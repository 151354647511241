export const GET_LABELS = 'GET_LABELS'
export const GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS'
export const GET_LABELS_FAILURE = 'GET_LABELS_FAILURE'

export const getLabels = (namespace, lang, isPrivate) => ({
	type: GET_LABELS,
	payload: {
		namespace,
		lang,
		isPrivate,
	},
})

export const getLabelsSuccess = (labels) => ({
	type: GET_LABELS_SUCCESS,
	payload: {
		labels,
	},
})

export const getLabelsFailure = (error) => ({
	type: GET_LABELS_FAILURE,
	payload: {
		error,
		errorString: error?.toString(),
	},
})
