import PropTypes from 'prop-types'
import React, { cloneElement } from 'react'

import Logo from '../../common/logo/Logo'

import * as Styled from './__styles__/Sidebar.styles'

const Sidebar = ({ children, isOpen }) => {
	const clonedChildren = children && cloneElement(children, { isOpen })

	return (
		<Styled.Root data-testid="__sidebar__" isopen={isOpen}>
			<Styled.LogoContainer>
				<Logo width={isOpen ? 100 : 25} {...{ isOpen }} />
			</Styled.LogoContainer>
			<div>{clonedChildren ? clonedChildren : children}</div>
		</Styled.Root>
	)
}

Sidebar.propTypes = {
	/* Defines the children of the compoent */
	children: PropTypes.element,
	/* Defines if the sidebar is open */
	isOpen: PropTypes.bool,
}

Sidebar.defaultProps = {
	children: null,
	isOpen: true,
}

export default Sidebar
