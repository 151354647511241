export const STARTUP = 'root/STARTUP'
export const STARTUP_SUCCESS = 'root/STARTUP_SUCCESS'
export const STARTUP_FAILURE = 'root/STARTUP_FAILURE'

export const startup = () => ({
	type: STARTUP,
})

export const startupSuccess = () => ({
	type: STARTUP_SUCCESS,
})

export const startupFailure = (error) => ({
	type: STARTUP_FAILURE,
	payload: {
		error,
	},
})
