import styled from 'styled-components'

import { Divider as AntdDivider } from 'antd'

const resolveDividerColor = (theme, isPe, index) => {
	if (isPe === 'false') return theme.colors.white

	switch (index) {
		case 0:
			return theme.colors.black

		case 1:
			return '#289dbd'

		case 2:
			return '#ff5950'

		case 3:
			return '#599d78'

		default:
			return theme.colors.white
	}
}

export const Root = styled.section`
	width: 100%;
	text-align: center;
	padding: ${({ theme }) => theme.spaces.medium} 0;
	color: ${({ theme }) => theme.colors.white} !important;
	background-color: ${({ theme }) => theme.colors.footerBackground};

	li,
	a {
		color: ${({ theme }) => theme.colors.white} !important;

		&:hover {
			color: ${({ theme }) => theme.colors.white} !important;
			opacity: 0.8;
		}
	}

	h3 {
		color: ${({ theme }) => theme.colors.white} !important;
	}
`

export const LinksContainer = styled.div`
	padding: 0 ${({ theme }) => theme.spaces.medium} ${({ theme }) => theme.spaces.medium}
		${({ theme }) => theme.spaces.medium};
	display: flex;
	margin: 0 auto;
	justify-content: center;
	flex-wrap: wrap;
	width: 80%;

	${({ theme }) => theme.devices.xs} {
		width: 100%;
	}
`

export const LinksItems = styled.div`
	flex: 0 20%;
	text-align: left;
	padding-right: ${({ theme }) => theme.spaces.small};

	${({ theme }) => theme.devices.xs} {
		flex: 0 100%;
	}

	ul {
		padding: 0;
		list-style-type: none;
		text-align: left;
	}

	ul,
	li {
		padding: 0;
		list-style-type: none;
		text-align: left;
	}
`

export const CreditsContainer = styled.div`
	width: 100%;

	& > div {
		margin: 0;
		color: ${({ theme }) => theme.colors.white};
	}
`

export const Link = styled.a`
	margin-left: ${({ theme }) => theme.spaces.xSmall};
`

export const Divider = styled(AntdDivider)`
	max-width: 60px;
	min-width: 60px;
	border-top: ${({ theme, ispe: isPe, index }) => `5px solid ${resolveDividerColor(theme, isPe, index)}`};
`
