import { Router } from '@reach/router'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import React, { lazy, Suspense } from 'react'

import { selectIsReady } from 'modules/root/selectors/rootSelectors'
import ErrorBoundary from 'components/error/ErrorBoundary'

import Main from './Main'

import * as Styled from './__styles__/App.styles'

const AuthRouter = lazy(() => import(/* webpackPrefetch: true */ 'modules/auth'))
const UsersPage = lazy(() => import(/* webpackPrefetch: true */ 'modules/users'))
const MessagesRouter = lazy(() => import(/* webpackPrefetch: true */ 'modules/messages'))
const ActivationRouter = lazy(() => import(/* webpackPrefetch: true */ 'modules/activation'))

const App = () => {
	const theme = useTheme()

	const isReady = useSelector(selectIsReady)

	return (
		isReady && (
			<Suspense fallback={<Styled.Loader size="large" data-testid="__animated-loader__" />}>
				<Styled.Root>
					<ErrorBoundary theme={theme}>
						<Router>
							<AuthRouter path="/auth/*" />
							<Main path="/">
								<UsersPage path="Users" />
								<MessagesRouter path="Messages/*" />
								<ActivationRouter path="Activation/*" />
							</Main>
						</Router>
					</ErrorBoundary>
				</Styled.Root>
			</Suspense>
		)
	)
}

export default App
