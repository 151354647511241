import { inRange } from 'lodash'
import axios from 'axios'

export const requestService = async (url, options = null) => {
	try {
		options = options || {
			method: 'GET',
			query: null,
			body: null,
			headers: {},
		}

		const response = await axios(url, {
			method: options.method,
			headers: options.headers,
			data: options.body,
			params: options.query,
			onUploadProgress: options.onUploadProgress,
			...options,
		})

		if (inRange(response.status, 199, 300)) {
			return {
				body: response.data,
				status: response.status,
				headers: response.headers,
			}
		} else {
			throw new Error(response)
		}
	} catch (err) {
		const errorLabel = err?.response?.data?.label ? err.response.data.label : err
		const status = err?.response?.data?.code ? err.response.data.code : err
		throw new HttpError(errorLabel, status, axios.isCancel(err))
	}
}

export class HttpError extends Error {
	constructor(body, status, isCancel = false) {
		super(body)
		this.body = body
		this.status = status
		this.isCancel = isCancel
	}

	toString() {
		return this.message.trim()
	}
}
