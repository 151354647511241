import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'

import { selectToken } from 'selectors/tokenSelectors'
import { selectUserInfo } from 'selectors/userInfoSelectors'

import Footer from 'components/common/footer/Footer'
import Sidebar from 'components/common/sidebar/Sidebar'
import Header from 'components/common/header/Header'
import NavMenu from 'components/common/navMenu/NavMenu'

import * as Styled from './__styles__/Main.styles'
import { selectUser } from 'selectors/userSelectors'

const Main = (props) => {
	const dispatch = useDispatch()

	const [isSideBarOpen, setIsSidebarOpen] = useState(true)

	const token = useSelector(selectToken)
	const user = useSelector(selectUser)

	const onOpenSidebarButtonClick = () => {
		setIsSidebarOpen((prev) => !prev)
	}

	return (
		<Styled.Root>
			<Sidebar isOpen={isSideBarOpen}>
				<NavMenu />
			</Sidebar>
			<Styled.Container>
				<Header onOpenSidebarButtonClick={onOpenSidebarButtonClick} user={user} />
				<Styled.Content>{props.children}</Styled.Content>
			</Styled.Container>
		</Styled.Root>
	)
}

export default Main
