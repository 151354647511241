import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
import decode from 'jwt-decode'

import { DESTROY_TOKEN_SUCCESS, STORE_TOKEN_SUCCESS, destroyToken, storeToken } from 'actions/tokenActions'
import { REQUEST_FAILURE, REQUEST_SUCCESS, request } from 'actions/apiActions'

import { setToStorageService } from 'services/storageServices'

import {
	POST_PASSWORD_RETRIEVE,
	POST_SIGNIN,
	PUT_PASSWORD_CREATE,
	SIGNOUT,
	STARTUP,
	postPasswordRetrieveFailure,
	postPasswordRetrieveSuccess,
	postSigninFailure,
	postSigninSuccess,
	putPasswordCreateFailure,
	putPasswordCreateSuccess,
	signoutFailure,
	signoutSuccess,
	startupFailure,
	startupSuccess,
	storeEmail,
	STORE_EMAIL,
	STORE_EMAIL_SUCCESS,
	storeEmailSuccess,
	storeEmailFailure,
} from 'modules/auth/actions/authActions'
import { resolveApiUrl } from 'utils/urlUtils'
import { useDispatch } from 'react-redux'
import { storeCurrentUser, STORE_CURRENT_USER_SUCCESS, destroyUser, DESTROY_USER_SUCCESS } from 'actions/userActions'
import { navigateTo } from 'actions/navActions'

export function* watchStartup() {
	yield takeLatest(STARTUP, startupSaga)
}

export function* startupSaga() {
	try {
		yield put(startupSuccess())
	} catch (err) {
		yield put(startupFailure(err))
	}
}

export function* watchStoreEmail() {
	yield takeLatest(STORE_EMAIL, storeEmailSaga)
}

export function* storeEmailSaga({ payload }) {
	try {
		yield put(storeEmailSuccess(payload.email))
	} catch (err) {
		yield put(storeEmailFailure(err))
	}
}

export function* watchPostSignin() {
	yield takeLatest(POST_SIGNIN, postSigninSaga)
}

export function* postSigninSaga({ payload }) {
	try {
		const url = resolveApiUrl(process.env.REACT_APP_SIGNIN_ROUTE)
		const options = {
			method: 'POST',
			body: {
				username: payload.formData.email,
				password: payload.formData.password,
				newpassword: payload.formData.newpassword,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		}
		yield put(request(url, options, 'signin_post'))
		const { success, failure } = yield race({
			success: take(({ type, payload }) => type === REQUEST_SUCCESS && payload.resourceId === 'signin_post'),
			failure: take(({ type, payload }) => type === REQUEST_FAILURE && payload.resourceId === 'signin_post'),
		})

		if (failure) {
			const {
				payload: {
					error: {
						body: {
							response: { data: err },
						},
					},
				},
			} = failure
			if (err.code === 'NewPasswordRequired') {
				yield put(storeEmail(payload.formData.email))
				yield take(STORE_EMAIL_SUCCESS)
				yield put(navigateTo('auth/force-changement-mot-de-passe'))
			} else {
				throw err
			}
		}
		if (success) {
			const {
				payload: {
					responseBody: { accessToken: token, user },
				},
			} = success
			/**** TOKEN STORAGE ****/
			if (token && user) {
				yield put(storeToken(token))
				yield take(STORE_TOKEN_SUCCESS)

				yield put(storeCurrentUser(user))
				yield take(STORE_CURRENT_USER_SUCCESS)

				yield put(postSigninSuccess(token))
			} else {
				yield put(postSigninFailure())
			}
		}
	} catch (err) {
		yield put(postSigninFailure(err))
	}
}

export function* watchPostPasswordRetrieve() {
	yield takeLatest(POST_PASSWORD_RETRIEVE, postPasswordRetrieveSaga)
}

export function* postPasswordRetrieveSaga({ payload }) {
	try {
		const url = resolveApiUrl(process.env.REACT_APP_PASSWORD_RESET_ROUTE)
		console.log(url)
		const options = {
			method: 'POST',
			body: {
				email: payload.email,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		}

		yield put(request(url, options, 'password-retrieve_post'))
		const { failure } = yield race({
			success: take(({ type, payload }) => type === REQUEST_SUCCESS && payload.resourceId === 'password-retrieve_post'),
			failure: take(({ type, payload }) => type === REQUEST_FAILURE && payload.resourceId === 'password-retrieve_post'),
		})

		if (failure) {
			throw new Error(failure.payload.errorString)
		}
		yield put(storeEmail(payload.email))
		yield put(postPasswordRetrieveSuccess())
	} catch (err) {
		yield put(postPasswordRetrieveFailure(err))
	}
}

export function* watchPutPasswordCreate() {
	yield takeLatest(PUT_PASSWORD_CREATE, putPasswordCreateSaga)
}

export function* putPasswordCreateSaga({ payload }) {
	try {
		const url = resolveApiUrl(process.env.REACT_APP_PASSWORD_RESET_ROUTE)
		console.log(payload)
		const options = {
			method: 'PUT',
			body: {
				password: payload.formData.password,
				email: payload.formData.email,
				code: payload.formData.code,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		}

		yield put(request(url, options, 'password-create_put'))
		const { failure } = yield race({
			success: take(({ type, payload }) => type === REQUEST_SUCCESS && payload.resourceId === 'password-create_put'),
			failure: take(({ type, payload }) => type === REQUEST_FAILURE && payload.resourceId === 'password-create_put'),
		})

		if (failure) {
			throw new Error(failure.payload.errorString)
		}

		yield put(putPasswordCreateSuccess())
	} catch (err) {
		yield put(putPasswordCreateFailure(err))
	}
}

export function* watchSignout() {
	yield takeLatest(SIGNOUT, signoutSaga)
}

export function* signoutSaga() {
	try {
		yield put(destroyToken())
		yield take(DESTROY_TOKEN_SUCCESS)

		yield put(destroyUser())
		yield take(DESTROY_USER_SUCCESS)

		yield put(signoutSuccess())
		yield put(navigateTo('/auth'))
	} catch (err) {
		yield put(signoutFailure(err))
	}
}

export default function* authSaga() {
	yield fork(watchStartup)
	yield fork(watchPostSignin)
	yield fork(watchStoreEmail)
	yield fork(watchPostPasswordRetrieve)
	yield fork(watchPutPasswordCreate)
	yield fork(watchSignout)
}
