import styled from 'styled-components'

export const Root = styled.aside`
	position: relative;
	min-height: 100vh;
	width: ${({ isopen }) => (isopen ? '250px' : null)};
	box-shadow: ${({ theme }) => theme.shadows.small};
	z-index: 2;
`

export const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
`

export const ToggleButton = styled.div`
	color: ${({ theme }) => theme.colors.white};
	bottom: 0;
	left: 0;
	right: 0;
	padding: ${({ theme }) => theme.spaces.small};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`
