import { fork, put, takeLatest } from 'redux-saga/effects'

import { GET_USER_INFO, getUserInfoFailure, getUserInfoSuccess } from 'actions/userInfoActions'

export function* watchGetUserInfo() {
	yield takeLatest(GET_USER_INFO, getUserInfoSaga)
}

export function* getUserInfoSaga(userInfo) {
	try {
		getUserInfoSuccess(userInfo)
	} catch (err) {
		yield put(getUserInfoFailure(err))
	}
}

export default function* userInfoSaga() {
	yield fork(watchGetUserInfo)
}
