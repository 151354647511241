import { fork, put, takeLatest } from 'redux-saga/effects'

import { GET_LABELS, getLabelsFailure, getLabelsSuccess } from 'actions/labelActions'

export function* watchgetLabels() {
	yield takeLatest(GET_LABELS, getLabelsaga)
}

export function* getLabelsaga({ payload }) {
	try {
		yield put(getLabelsSuccess(null))
	} catch (err) {
		yield put(getLabelsFailure(err))
	}
}

export default function* labelSagas() {
	yield fork(watchgetLabels)
}
