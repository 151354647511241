export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'

export const getUserInfo = (userInfo) => ({
	type: GET_USER_INFO,
	payload: {
		userInfo,
	},
})

export const getUserInfoSuccess = (userInfo) => ({
	type: GET_USER_INFO_SUCCESS,
	payload: {
		userInfo,
	},
})

export const getUserInfoFailure = (err) => ({
	type: GET_USER_INFO_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
	},
})
