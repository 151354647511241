import { setToStorageService } from 'services/storageServices'
import {
	STORE_CURRENT_USER,
	STORE_CURRENT_USER_SUCCESS,
	storeCurrentUser,
	storeCurrentUserSuccess,
	storeCurrentUserFailure,
	GET_CURRENT_USER,
	getCurrentUserSuccess,
	getCurrentUserFailure,
	DESTROY_USER,
	destroyUserSuccess,
	destroyUserFailure,
} from 'actions/userActions'
import { resolveApiUrl } from 'utils/urlUtils'
import { request, REQUEST_SUCCESS, REQUEST_FAILURE } from 'actions/apiActions'
import { fork, put, race, take, takeLatest } from 'redux-saga/effects'

import { formatUser } from 'services/currentUserService'

export function* watchStoreUser() {
	yield takeLatest(STORE_CURRENT_USER, storeUserSaga)
}

export function* storeUserSaga({ payload, type }) {
	try {
		setToStorageService('user', payload.user)
		put(storeCurrentUserSuccess(payload.user))
	} catch (err) {
		yield put(storeCurrentUserFailure(err))
	}
}

export function* watchGetCurrentUser() {
	yield takeLatest(GET_CURRENT_USER, getCurrentUserSaga)
}

export function* getCurrentUserSaga({ payload, type }) {
	try {
		const url = resolveApiUrl(process.env.REACT_APP_USER_ROUTE)
		const options = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}
		yield put(request(url, options, 'current_user_get'))
		const { success, failure } = yield race({
			success: take(({ type, payload }) => type === REQUEST_SUCCESS && payload.resourceId === 'current_user_get'),
			failure: take(({ type, payload }) => type === REQUEST_FAILURE && payload.resourceId === 'current_user_get'),
		})
		if (failure) {
			const {
				payload: { error },
			} = failure
			throw new Error(error.message)
		}
		const usr = formatUser(success.payload.responseBody)
		yield put(storeCurrentUser(usr))
		yield take(STORE_CURRENT_USER_SUCCESS)

		yield put(getCurrentUserSuccess(success.payload.responseBody))
	} catch (err) {
		yield put(getCurrentUserFailure(err))
	}
}

export function* watchDestroyUser() {
	yield takeLatest(DESTROY_USER, destroyUserSaga)
}

export function* destroyUserSaga() {
	try {
		setToStorageService('user')
		yield put(destroyUserSuccess())
	} catch (err) {
		yield put(destroyUserFailure(err))
	}
}

export default function* userSaga() {
	yield fork(watchStoreUser)
	yield fork(watchGetCurrentUser)
	yield fork(watchDestroyUser)
}
