import { call, put, select, takeLatest, fork } from 'redux-saga/effects'
import { navigate } from '@reach/router'

import {
	HOLD_NAVIGATION,
	holdNavigationFailure,
	holdNavigationSuccess,
	NAVIGATE_BACK,
	NAVIGATE_TO,
	navigateBackFailure,
	navigateBackSuccess,
	navigateToFailure,
	navigateToSuccess,
} from '../actions/navActions'

export function* watchNavigateTo() {
	yield takeLatest(NAVIGATE_TO, navigateToSaga)
}

export function* navigateToSaga({ payload }) {
	const { pathOrId } = payload
	try {
		yield call(navigate, pathOrId)

		yield put(navigateToSuccess(pathOrId))
	} catch (err) {
		yield put(navigateToFailure(pathOrId, err))
	}
}

export function* watchNavigateBack() {
	yield takeLatest(NAVIGATE_BACK, navigateBackSaga)
}

export function* navigateBackSaga() {
	try {
		window.history.back()

		yield put(navigateBackSuccess())
	} catch (err) {
		yield put(navigateBackFailure(err))
	}
}

export function* watchHoldNavigation() {
	yield takeLatest(HOLD_NAVIGATION, holdNavigationSaga)
}

export function* holdNavigationSaga({ payload }) {
	try {
		yield put(holdNavigationSuccess(payload.pathOrId))
	} catch (err) {
		yield put(holdNavigationFailure(payload.pathOrId, err))
	}
}

export default function* navSagas() {
	yield fork(watchNavigateTo)
	yield fork(watchNavigateBack)
	yield fork(watchHoldNavigation)
}
