export const HOLD_NAVIGATION = 'HOLD_NAVIGATION'
export const HOLD_NAVIGATION_SUCCESS = 'HOLD_NAVIGATION_SUCCESS'
export const HOLD_NAVIGATION_FAILURE = 'HOLD_NAVIGATION_FAILURE'

export const NAVIGATE_TO = 'NAVIGATE_TO'
export const NAVIGATE_TO_SUCCESS = 'NAVIGATE_TO_SUCCESS'
export const NAVIGATE_TO_FAILURE = 'NAVIGATE_TO_FAILURE'

export const NAVIGATE_BACK = 'NAVIGATE_BACK'
export const NAVIGATE_BACK_SUCCESS = 'NAVIGATE_BACK_SUCCESS'
export const NAVIGATE_BACK_FAILURE = 'NAVIGATE_BACK_FAILURE'

export const navigateTo = (pathOrId, message) => ({
	type: NAVIGATE_TO,
	payload: {
		pathOrId,
		message,
	},
})

export const navigateToSuccess = (pathOrId) => ({
	type: NAVIGATE_TO_SUCCESS,
	payload: {
		pathOrId,
	},
})

export const navigateToFailure = (pathOrId, err) => ({
	type: NAVIGATE_TO_FAILURE,
	payload: {
		pathOrId,
		error: err && err.toString(),
	},
})

export const navigateBack = () => ({
	type: NAVIGATE_BACK,
})

export const navigateBackSuccess = () => ({
	type: NAVIGATE_BACK_SUCCESS,
})

export const navigateBackFailure = (err) => ({
	type: NAVIGATE_BACK_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})

export const holdNavigation = (pathOrId) => ({
	type: HOLD_NAVIGATION,
	payload: {
		pathOrId,
	},
})

export const holdNavigationSuccess = (pathOrId) => ({
	type: HOLD_NAVIGATION_SUCCESS,
	payload: {
		pathOrId,
	},
})

export const holdNavigationFailure = (pathOrId, err) => ({
	type: HOLD_NAVIGATION_FAILURE,
	payload: {
		pathOrId,
		error: err && err.toString(),
	},
})
