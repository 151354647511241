import { Button, Typography } from 'antd'
import React, { Component } from 'react'

import * as Styled from './__styles__/ErrorBoundary.styles'
import ErrorBoundarySvg from './__svg__/ErrorBoundarySvg'

const { Title, Paragraph } = Typography

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { error: null }
	}

	static getDerivedStateFromError(error) {
		return { error }
	}

	componentDidCatch(error, info) {
		console.error('Crash:', error, info)
	}

	render() {
		const { children, theme, t } = this.props
		const { error } = this.state
		const { primary, secondary, darkPrimary } = theme.colors

		if (error) {
			return (
				<Styled.Root>
					<ErrorBoundarySvg primaryColor={primary} secondaryColor={secondary} darkPrimaryColor={darkPrimary} />
					<Title level={1} data-testid="__error-boundary-title__">
						{'root:errorBoundary.title'}
					</Title>
					<Paragraph data-testid="__error-boundary-description__">{'root:errorBoundary.description'}</Paragraph>
					<Button data-testid="__error-boundary-button__" type="primary" onClick={() => (window.location.href = '/')}>
						{'root:errorBoundary.button'}
					</Button>
				</Styled.Root>
			)
		}
		return children
	}
}

export default ErrorBoundary
