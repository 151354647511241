import { call, fork, put, takeEvery } from 'redux-saga/effects'

import {
	GET_FROM_COOKIES,
	REMOVE_FROM_COOKIES,
	SET_TO_COOKIES,
	getFromCookiesFailure,
	getFromCookiesSuccess,
	removeFromCookiesFailure,
	removeFromCookiesSuccess,
	setToCookiesFailure,
	setToCookiesSuccess,
} from 'actions/cookieActions'

import { getFromCookiesService, removeFromCookiesService, setToCookiesService } from 'services/cookiesServices'

export function* watchGetFromCookies() {
	yield takeEvery(GET_FROM_COOKIES, getFromCookiesSaga)
}

export function* getFromCookiesSaga({ payload }) {
	const { name, resourceId } = payload
	try {
		const value = yield call(getFromCookiesService, name)
		yield put(getFromCookiesSuccess(value, name, resourceId))
	} catch (err) {
		yield put(getFromCookiesFailure(err, name))
	}
}

export function* watchSetToCookies() {
	yield takeEvery(SET_TO_COOKIES, setToCookiesSaga)
}

export function* setToCookiesSaga({ payload }) {
	const { value, name, resourceId } = payload
	try {
		if (value) {
			yield call(setToCookiesService, value, name)
		} else {
			throw new Error('Oops!! null or undefined cookies')
		}

		yield put(setToCookiesSuccess(name, resourceId))
	} catch (err) {
		yield put(setToCookiesFailure(err, name))
	}
}

export function* watchRemoveFromCookies() {
	yield takeEvery(REMOVE_FROM_COOKIES, removeFromCookiesSaga)
}

export function* removeFromCookiesSaga({ payload }) {
	const { name, resourceId } = payload
	try {
		yield call(removeFromCookiesService, name)

		yield put(removeFromCookiesSuccess(name, resourceId))
	} catch (err) {
		yield put(removeFromCookiesFailure(err, name))
	}
}

export default function* cookiesSagas() {
	yield fork(watchGetFromCookies)
	yield fork(watchSetToCookies)
	yield fork(watchRemoveFromCookies)
}
