import { fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { FETCH_TOKEN_SUCCESS, fetchToken } from 'actions/tokenActions'
import { GET_USER_INFO_SUCCESS, getUserInfo, storeCurrentUser } from 'actions/userActions'
import { selectToken } from 'selectors/tokenSelectors'
import { selectUser } from 'selectors/userSelectors'

import { STARTUP, startupFailure, startupSuccess } from 'modules/root/actions/rootActions'
import { navigateTo } from '../../../actions/navActions'
import { getFromStorageService } from 'services/storageServices'

export function* watchStartup() {
	yield takeLatest(STARTUP, startupSaga)
}

export function* startupSaga() {
	try {
		/**** TOKEN ****/
		yield put(fetchToken())
		yield take(FETCH_TOKEN_SUCCESS)

		const token = yield select(selectToken)
		let user = yield select(selectUser)
		if (!user || !user.email) {
			user = getFromStorageService('user')
			if (user && user.email) yield put(storeCurrentUser(user))
		}
		if (token && user && user.email) {
			yield put(navigateTo('/Users'))
		} else {
			yield put(navigateTo('/auth'))
		}

		yield put(startupSuccess())
	} catch (err) {
		yield put(startupFailure(err))
	}
}

export default function* rootSagas() {
	yield fork(watchStartup)
}
