export const REQUEST = 'api/request'
export const REQUEST_SUCCESS = 'api/requestSuccess'
export const REQUEST_FAILURE = 'api/requestFailure'

export const request = (requestUrl, requestOptions = null, resourceId = null, isCancellable = false) => ({
	type: REQUEST,
	payload: {
		requestUrl,
		requestOptions,
		resourceId,
		isCancellable,
	},
})

export const requestSuccess = (responseBody, responseStatus, responseHeaders = null, resourceId = null) => ({
	type: REQUEST_SUCCESS,
	payload: {
		responseBody,
		responseStatus,
		responseHeaders,
		resourceId,
	},
})

export const requestFailure = (err, resourceId = null) => ({
	type: REQUEST_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
		resourceId,
	},
})
