export const SET_TO_COOKIES = 'SET_TO_COOKIES'
export const SET_TO_COOKIES_SUCCESS = 'SET_TO_COOKIES_SUCCESS'
export const SET_TO_COOKIES_FAILURE = 'SET_TO_COOKIES_FAILURE'

export const GET_FROM_COOKIES = 'GET_FROM_COOKIES'
export const GET_FROM_COOKIES_SUCCESS = 'GET_FROM_COOKIES_SUCCESS'
export const GET_FROM_COOKIES_FAILURE = 'GET_FROM_COOKIES_FAILURE'

export const REMOVE_FROM_COOKIES = 'REMOVE_FROM_COOKIES'
export const REMOVE_FROM_COOKIES_SUCCESS = 'REMOVE_FROM_COOKIES_SUCCESS'
export const REMOVE_FROM_COOKIES_FAILURE = 'REMOVE_FROM_COOKIES_FAILURE'

export const setToCookies = (value, name, resourceId) => ({
	type: SET_TO_COOKIES,
	payload: {
		value,
		name,
		resourceId,
	},
})

export const setToCookiesSuccess = (name, resourceId) => ({
	type: SET_TO_COOKIES_SUCCESS,
	payload: {
		name,
		resourceId,
	},
})

export const setToCookiesFailure = (error, name, resourceId) => ({
	type: SET_TO_COOKIES_FAILURE,
	payload: {
		name,
		error,
		errorString: error && error.toString(),
		resourceId,
	},
})

export const getFromCookies = (name, resourceId) => ({
	type: GET_FROM_COOKIES,
	payload: {
		name,
		resourceId,
	},
})

export const getFromCookiesSuccess = (value, name, resourceId) => ({
	type: GET_FROM_COOKIES_SUCCESS,
	payload: {
		value,
		name,
		resourceId,
	},
})

export const getFromCookiesFailure = (error, name, resourceId) => ({
	type: GET_FROM_COOKIES_FAILURE,
	payload: {
		name,
		error,
		errorString: error && error.toString(),
		resourceId,
	},
})

export const removeFromCookies = (name, resourceId) => ({
	type: REMOVE_FROM_COOKIES,
	payload: {
		name,
		resourceId,
	},
})

export const removeFromCookiesSuccess = (name, resourceId) => ({
	type: REMOVE_FROM_COOKIES_SUCCESS,
	payload: {
		name,
		resourceId,
	},
})

export const removeFromCookiesFailure = (error, name, resourceId) => ({
	type: REMOVE_FROM_COOKIES_FAILURE,
	payload: {
		name,
		error,
		errorString: error && error.toString(),
		resourceId,
	},
})
