import { startup } from 'modules/root/actions/rootActions'
import authSagas from 'modules/auth/sagas/authSagas'
import rootReducer from 'modules/root/reducers/rootReducers'
import rootSagas from 'modules/root/sagas/rootSagas'
import tokenReducer from 'reducers/tokenReducers'
import userReducers from 'reducers/userReducers'

const rootModule = {
	id: 'root',
	reducerMap: {
		root: rootReducer,
		token: tokenReducer,
		user: userReducers,
	},
	sagas: [rootSagas, authSagas],
	initialActions: [startup()],
}

export default rootModule
