import styled from 'styled-components'

export const SkipToContent = styled.a`
	width: 100%;
	text-align: center;
	padding: 8px;
	position: absolute;
	transform: translateY(-110px);
	transition: transform 0.3s;
	top: -10px;

	&:focus {
		transform: translateY(0px);
		color: ${({ theme }) => theme.colors.hover};
	}
`
