import { isNil } from 'lodash'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

import * as Logos from '__assets__/logos'
import * as Styled from './__styles__/Logo.styles'

const Logo = ({ width, isOpen, isDark, isHeader }) => {
	const customer = 'enedis'
	const theme = useTheme()

	const resolveLogo = () => {
		switch (true) {
			case isDark:
				return Logos[customer].Dark

			case isHeader:
				return Logos[customer].Header

			case isOpen:
				return Logos[customer].Base

			case !isNil(isOpen) && !isOpen:
				return Logos[customer].Icon

			default:
				return Logos[customer].Base
		}
	}

	const CurrentLogo = resolveLogo()

	return (
		<Styled.Root width={width}>
			<a href="/" target="_blank" data-testid="__logo__">
				<CurrentLogo
					primaryColor={theme.colors.primary}
					darkPrimaryColor={theme.colors.darkPrimary}
					secondaryColor={theme.colors.secondary}
				/>
			</a>
		</Styled.Root>
	)
}

Logo.propTypes = {
	width: PropTypes.number,
	isOpen: PropTypes.bool,
	isDark: PropTypes.bool,
	isHeader: PropTypes.bool,
}

Logo.defaultProps = {
	width: 200,
	isOpen: null,
	isDark: null,
	isHeader: null,
}

export default Logo
