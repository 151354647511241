/**
 * Returns url with replaced properties.
 * @param endpoint  Url.
 * @param options   Optional object that contains key/value pairs to replace endpoint dynamic properties.
 * @returns {string} Url.
 */
export const resolveApiUrl = (endpoint, options = null) => {
	let url = endpoint

	if (options) {
		Object.keys(options).forEach((objectKey) => {
			const value = options[objectKey]
			url = url.replace(new RegExp(`{{${objectKey}}}`, 'g'), encodeURIComponent(value))
		})
	}

	return `${process.env.REACT_APP_API_URL}${url}`
}
