import { Layout } from 'antd'
import styled from 'styled-components'

const { Header: AntdHeader } = Layout

export const Root = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${({ theme }) => theme.spaces.small} ${({ theme }) => theme.spaces.large};
	border-bottom: 1px solid ${({ theme }) => theme.colors.background};
	color: ${({ theme }) => theme.colors.primary};
	height: auto;
	background-color: ${({ theme }) => theme.colors.white};

	& > h1 {
		margin: 0;
	}

	& > .ant-form-item {
		margin: 0px;
	}

	${({ theme }) => theme.devices.xs} {
		padding: ${({ theme }) => theme.spaces.small} ${({ theme }) => theme.spaces.medium};
	}
`

export const Header = styled(AntdHeader)`
	line-height: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	background: ${({ theme }) => theme.colors.white};
	padding: 0;
	width: 100%;
`
export const ProfilButtonContainer = styled.div`
	display: flex;
	float: right;
	align-items: center;
	justify-content: space-between;
	padding: ${({ theme }) => theme.spaces.xSmall} ${({ theme }) => theme.spaces.small};
	cursor: pointer;
`

export const ProfilLeftSide = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.font};

	& > span:first-child {
		margin-right: ${({ theme }) => theme.spaces.small};
	}
`

export const ToggleButton = styled.div`
	font-size: 20px;
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
`
