import produce, { enableES5 } from 'immer'

import {
	STORE_CURRENT_USER,
	STORE_CURRENT_USER_SUCCESS,
	DESTROY_USER_SUCCESS,
	GET_CURRENT_USER_SUCCESS,
} from '../actions/userActions'

enableES5()

const initialState = {
	id: null,
	email: null,
	firstName: null,
	lastName: null,
	profile: null,
}

export default (state, { type, payload }) => {
	return produce(state || initialState, (draft) => {
		switch (type) {
			case STORE_CURRENT_USER_SUCCESS:
			case STORE_CURRENT_USER: {
				draft.id = payload.user.sub
				draft.email = payload.user.email
				draft.firstName = payload.user.name
				draft.profile = payload.user.profile
				draft.lastName = payload.user.family_name
				break
			}
			case GET_CURRENT_USER_SUCCESS: {
				return payload.user
			}
			case DESTROY_USER_SUCCESS:
				return null

			default: {
				state
				break
			}
		}
	})
}
