import React from 'react'

import * as Styled from './__styles__/SkipToContent.styles'

const SkipToContent = () => {
	return (
		<Styled.SkipToContent role="button" href="#skipToFirstContent">
			{'root:skipToFirstContent'}
		</Styled.SkipToContent>
	)
}

SkipToContent.propTypes = {}

SkipToContent.defaultProps = {}

export default SkipToContent
