import { DownOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import { signout } from 'modules/auth/actions/authActions'

import SkipToContent from 'components/common/skipToContent/SkipToContent'

import * as Styled from './__styles__/Header.styles'

const Header = ({ user, onOpenSidebarButtonClick }) => {
	const dispatch = useDispatch()

	const onSignoutClick = () => {
		dispatch(signout())
	}

	const resolveMenuItems = (
		<Menu>
			<Menu.Item onClick={onSignoutClick}>{'Se déconnecter'}</Menu.Item>
		</Menu>
	)

	const resolveProfileName = ({ firstName, lastName }) => firstName + ' ' + lastName

	return (
		<Styled.Root id="header">
			<SkipToContent href="#skipToFirstContent">{'root:skipToFirstContent'}</SkipToContent>
			<Styled.Header>
				<Styled.ToggleButton
					role="button"
					tabIndex="0"
					onKeyPress={({ key }) => key === 'Enter' && onOpenSidebarButtonClick()}
					onClick={() => onOpenSidebarButtonClick()}
					data-testid="__header-toggle-button__"
				>
					{<MenuFoldOutlined />}
				</Styled.ToggleButton>

				<Dropdown overlay={resolveMenuItems} overlayStyle={{ border: '0px', margin: '1em' }}>
					<Styled.ProfilButtonContainer>
						<Styled.ProfilLeftSide>
							{user ? <span data-testid="__header-user-info__">{resolveProfileName(user)}</span> : null}
						</Styled.ProfilLeftSide>
						<DownOutlined />
					</Styled.ProfilButtonContainer>
				</Dropdown>
			</Styled.Header>
		</Styled.Root>
	)
}

Header.propTypes = {
	/* Defines the current user*/
	user: PropTypes.object,
	/* Defines the handler to open or close th sidebar */
	onOpenSidebarButtonClick: PropTypes.func,
}

Header.defaultProps = {
	user: null,
	onOpenSidebarButtonClick: null,
}

export default Header
