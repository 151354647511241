import React from 'react'

const Base = ({ primaryColor, darkPrimaryColor, secondaryColor }) => {
	return (
		<svg width="237" height="75" viewBox="0 0 237 75" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path
					d="M108.9 39.2C104 39.2 99.9 35.5 99.9 30.9V29.4H119C125.6 29.4 131 24.4 131 18.3V11.2C131 5 125.7 0 119.1 0H102.4C101.5 0 100.5 0 99.6 0.2C94.2 1.4 90.4 6 90.4 11.1V15.2C90.4 16.1 91.1 16.9 91.9 16.9H98.4C99.2 16.9 99.9 16.1 99.9 15.2V14.2V12.6C99.9 10.5 101.8 8.7 104.1 8.7H117.2C119.5 8.7 121.4 10.4 121.4 12.6V16.8C121.4 18.9 119.5 20.7 117.2 20.7H84.9V29.4H90.3V30.9C90.3 40.3 98.6 47.9 108.8 47.9H136.4V39.2H108.9Z"
					fill={secondaryColor}
				/>
				<path
					d="M66.4 8.7C71.3 8.7 75.4 12.4 75.4 17V46.3C75.4 47.1 76.1999 47.8 77.0999 47.8H83.1999C84.0999 47.8 84.9 47.1 84.9 46.3V17.1C84.9 7.7 76.6 0 66.4 0H46.7C45.9 0 45.2 0.8 45.2 1.7V46.2C45.2 47.1 45.9 47.9 46.7 47.9H53.2C54 47.9 54.7 47.1 54.7 46.2V8.7H66.4Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M12.9 8.7H38C38.9 8.7 39.7 8 39.7 7.2V1.5C39.7 0.7 39 0 38.1 0H12.8C5.7 0 0 5.3 0 11.8V36.1C0 42.6 5.7 47.9 12.8 47.9H38.1C39 47.9 39.8 47.2 39.8 46.4V40.7C39.8 39.9 39.1 39.2 38.1 39.2H12.9C11.1 39.2 9.5 37.7 9.5 36V29.3H24.5C25.4 29.3 26.2 28.6 26.2 27.8V22.2C26.2 21.4 25.5 20.7 24.5 20.7H9.5V12C9.5 10.2 11.1 8.7 12.9 8.7Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M236.9 31.4C236.9 31 236.9 30.5 236.9 30C236.4 24.7 231 20.7 225.2 20.7H210.9H210.5C208.2 20.7 206.3 18.7 206.3 16.2V12.9C206.3 10.6 208.3 8.7 210.8 8.7H216.5H216.6H230.9C231.6 8.7 232.3 8.3 232.5 7.6C232.6 7.4 232.6 7.3 232.6 7.1V1.5C232.6 0.7 231.8 0 230.9 0H208.6C202.1 0 196.8 4.9 196.8 10.9V17.7C196.8 24.2 201.7 29.4 207.7 29.4H223.3C225.6 29.4 227.4 31.4 227.4 33.9V35C227.4 37.3 225.4 39.2 222.9 39.2H198.5C197.8 39.2 197.1 39.6 196.9 40.3C196.8 40.5 196.8 40.6 196.8 40.8V46.5C196.8 47.3 197.6 48 198.5 48H218.5H225.3C231.8 48 237 43.1 237 37.1V31.4H236.9Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M158.8 0H138C137.2 0 136.5 0.8 136.5 1.7V29.9C136.5 30.8 137.2 31.6 138 31.6H144.5C145.3 31.6 146 30.8 146 29.9V8.8H157.7C162.6 8.8 166.7 12.5 166.7 17.1V32.5C166.7 37.1 162.7 39.2 157.7 39.2H136.5V47.9H157.7C167.9 47.9 176.2 41.9 176.2 32.5V17C176.3 7.9 168.7 0 158.8 0Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191.3 30.2V14.2C191.3 13.3 190.6 12.5 189.8 12.5H183.3C182.5 12.5 181.8 13.3 181.8 14.2V30.2V46.2C181.8 47.1 182.5 47.9 183.3 47.9H189.8C190.6 47.9 191.3 47.1 191.3 46.2V30.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191.3 3.6V1.7C191.3 0.8 190.6 0 189.8 0H183.3C182.5 0 181.8 0.8 181.8 1.7V3.6V7C181.8 7.9 182.5 8.7 183.3 8.7H189.8C190.6 8.7 191.3 7.9 191.3 7V3.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M0 73.6V61.9C0 61.7 0.1 61.5 0.4 61.4C0.6 61.3 1 61.2 1.3 61.2C1.7 61.2 2 61.3 2.2 61.4C2.4 61.5 2.6 61.7 2.6 61.9V72.4H7.4C7.6 72.4 7.8 72.5 8 72.7C8.1 72.9 8.2 73.1 8.2 73.4C8.2 73.7 8.1 73.9 8 74.1C7.9 74.3 7.7 74.4 7.4 74.4H1.1C0.8 74.4 0.5 74.3 0.3 74.2C0.1 74 0 73.9 0 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M14.2 73.6V61.9C14.2 61.7 14.3 61.5 14.5 61.4C14.7 61.3 15 61.2 15.3 61.2H22.3C22.6 61.2 22.8 61.3 22.9 61.5C23 61.7 23.1 61.9 23.1 62.2C23.1 62.5 23 62.7 22.9 62.9C22.8 63.1 22.6 63.2 22.3 63.2H16.7V66.8H19.7C19.9 66.8 20.1 66.9 20.3 67.1C20.4 67.3 20.5 67.5 20.5 67.8C20.5 68 20.4 68.2 20.3 68.4C20.2 68.6 20 68.7 19.7 68.7H16.7V72.3H22.3C22.5 72.3 22.7 72.4 22.9 72.6C23 72.8 23.1 73.1 23.1 73.3C23.1 73.6 23 73.8 22.9 74C22.8 74.2 22.6 74.3 22.3 74.3H15.3C15 74.3 14.7 74.2 14.5 74.1C14.3 74 14.2 73.9 14.2 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M25.9 73.6V61.9C25.9 61.7 26 61.5 26.3 61.4C26.5 61.3 26.9 61.2 27.2 61.2C27.6 61.2 27.9 61.3 28.1 61.4C28.3 61.5 28.5 61.7 28.5 61.9V72.4H33.3C33.5 72.4 33.7 72.5 33.9 72.7C34 72.9 34.1 73.1 34.1 73.4C34.1 73.7 34 73.9 33.9 74.1C33.8 74.3 33.6 74.4 33.3 74.4H27C26.7 74.4 26.4 74.3 26.2 74.2C26 74 25.9 73.9 25.9 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M36.7 73.6V61.9C36.7 61.7 36.8 61.5 37 61.4C37.2 61.3 37.4999 61.2 37.7999 61.2H44.7999C45.0999 61.2 45.3 61.3 45.4 61.5C45.5 61.7 45.5999 61.9 45.5999 62.2C45.5999 62.5 45.5 62.7 45.4 62.9C45.3 63.1 45.0999 63.2 44.7999 63.2H39.2V66.8H42.2C42.4 66.8 42.5999 66.9 42.7999 67.1C42.8999 67.3 43 67.5 43 67.8C43 68 42.8999 68.2 42.7999 68.4C42.6999 68.6 42.5 68.7 42.2 68.7H39.2V72.3H44.7999C44.9999 72.3 45.2 72.4 45.4 72.6C45.5 72.8 45.5999 73.1 45.5999 73.3C45.5999 73.6 45.5 73.8 45.4 74C45.3 74.2 45.0999 74.3 44.7999 74.3H37.7999C37.4999 74.3 37.2 74.2 37 74.1C36.8 74 36.7 73.9 36.7 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M48.1 70.3V65.2C48.1 63.8 48.5 62.7 49.4 62.1C50.2 61.4 51.3 61.1 52.7 61.1C54.1 61.1 55.2 61.4 56.1 62C57 62.6 57.4 63.5 57.4 64.5C57.4 64.9 57.3 65.3 57.1 65.4C56.9 65.6 56.6 65.6 56.1 65.6C55.3 65.6 54.8 65.4 54.8 64.9C54.8 64.7 54.8 64.6 54.8 64.5C54.8 64.4 54.7 64.2 54.6 64C54.5 63.8 54.4 63.7 54.3 63.5C54.2 63.4 54 63.3 53.7 63.2C53.4 63.1 53.1 63.1 52.8 63.1C51.4 63.1 50.7 63.8 50.7 65.2V70.3C50.7 71.7 51.4 72.4 52.8 72.4C53.2 72.4 53.6 72.3 53.9 72.2C54.2 72.1 54.4 71.9 54.5 71.7C54.6 71.5 54.7 71.3 54.8 71.1C54.8 70.9 54.9 70.7 54.9 70.5C54.9 70 55.4 69.8 56.2 69.8C56.7 69.8 57 69.9 57.2 70C57.4 70.2 57.5 70.5 57.5 70.9C57.5 72 57.1 72.9 56.2 73.5C55.3 74.2 54.2 74.5 52.8 74.5C51.4 74.5 50.3 74.2 49.5 73.5C48.5 72.8 48.1 71.8 48.1 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M59.4 62.2C59.4 61.9 59.5 61.7 59.6 61.5C59.7 61.3 59.9 61.2 60.2 61.2H68.6C68.9 61.2 69.1 61.3 69.2 61.5C69.3 61.7 69.4 61.9 69.4 62.2C69.4 62.5 69.3 62.7 69.2 62.9C69.1 63.1 68.9 63.2 68.6 63.2H65.7V73.6C65.7 73.8 65.6 74 65.3 74.1C65 74.2 64.7 74.3 64.4 74.3C64 74.3 63.7 74.2 63.5 74.1C63.2 74 63.1 73.8 63.1 73.6V63.2H60.2C59.9 63.2 59.8 63.1 59.6 62.9C59.4 62.7 59.4 62.4 59.4 62.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M72 73.6V61.9C72 61.7 72.1 61.5 72.2 61.4C72.4 61.3 72.6 61.2 72.8 61.2H76.7C79.8 61.2 81.4 62.5 81.4 65C81.4 66 81.2 66.8 80.7 67.4C80.2 68 79.6 68.4 78.8 68.6L81.4 73C81.5 73.1 81.5 73.2 81.5 73.3C81.5 73.6 81.3 73.9 81 74.1C80.7 74.4 80.3 74.5 79.9 74.5C79.5 74.5 79.2 74.3 79 73.9L76.2 68.9H74.5V73.6C74.5 73.8 74.4 74 74.1 74.1C73.8 74.2 73.5 74.3 73.2 74.3C72.9 74.3 72.6 74.2 72.3 74.1C72.1 74 72 73.9 72 73.6ZM74.6 67.1H76.8C78.2 67.1 78.9 66.5 78.9 65.2C78.9 63.9 78.2 63.3 76.8 63.3H74.6V67.1Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M84.5 73.6V61.9C84.5 61.7 84.6 61.5 84.9 61.4C85.1 61.3 85.5 61.2 85.8 61.2C86.2 61.2 86.5 61.3 86.7 61.4C86.9 61.5 87.1 61.7 87.1 61.9V73.7C87.1 73.9 87 74.1 86.7 74.2C86.4 74.3 86.1 74.4 85.8 74.4C85.4 74.4 85.1 74.3 84.9 74.2C84.6 74 84.5 73.9 84.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M90.5 70.3V65.2C90.5 63.8 90.9 62.7 91.8 62.1C92.6 61.4 93.7 61.1 95.1 61.1C96.5 61.1 97.6 61.4 98.5 62C99.4 62.6 99.8 63.5 99.8 64.5C99.8 64.9 99.7 65.3 99.5 65.4C99.3 65.6 99 65.6 98.5 65.6C97.7 65.6 97.2 65.4 97.2 64.9C97.2 64.7 97.2 64.6 97.2 64.5C97.2 64.4 97.1 64.2 97 64C96.9 63.8 96.8 63.7 96.7 63.5C96.6 63.4 96.4 63.3 96.1 63.2C95.8 63.1 95.5 63.1 95.2 63.1C93.8 63.1 93.1 63.8 93.1 65.2V70.3C93.1 71.7 93.8 72.4 95.2 72.4C95.6 72.4 96 72.3 96.3 72.2C96.6 72.1 96.8 71.9 96.9 71.7C97 71.5 97.1 71.3 97.2 71.1C97.2 70.9 97.3 70.7 97.3 70.5C97.3 70 97.8 69.8 98.6 69.8C99.1 69.8 99.4 69.9 99.6 70C99.8 70.2 99.9 70.5 99.9 70.9C99.9 72 99.5 72.9 98.6 73.5C97.7 74.2 96.6 74.5 95.2 74.5C93.8 74.5 92.7 74.2 91.9 73.5C90.9 72.8 90.5 71.8 90.5 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M102.8 73.6V61.9C102.8 61.7 102.9 61.5 103.2 61.4C103.4 61.3 103.8 61.2 104.1 61.2C104.5 61.2 104.8 61.3 105 61.4C105.2 61.5 105.4 61.7 105.4 61.9V73.7C105.4 73.9 105.3 74.1 105 74.2C104.7 74.3 104.4 74.4 104.1 74.4C103.7 74.4 103.4 74.3 103.2 74.2C102.9 74 102.8 73.9 102.8 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M108 62.2C108 61.9 108.1 61.7 108.2 61.5C108.3 61.3 108.5 61.2 108.8 61.2H117.2C117.5 61.2 117.7 61.3 117.8 61.5C117.9 61.7 118 61.9 118 62.2C118 62.5 117.9 62.7 117.8 62.9C117.7 63.1 117.5 63.2 117.2 63.2H114.3V73.6C114.3 73.8 114.2 74 113.9 74.1C113.6 74.2 113.3 74.3 113 74.3C112.6 74.3 112.3 74.2 112.1 74.1C111.8 74 111.7 73.8 111.7 73.6V63.2H108.8C108.5 63.2 108.4 63.1 108.2 62.9C108.1 62.7 108 62.4 108 62.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M120.6 73.6V61.9C120.6 61.7 120.7 61.5 120.9 61.4C121.1 61.3 121.4 61.2 121.7 61.2H128.7C129 61.2 129.2 61.3 129.3 61.5C129.4 61.7 129.5 61.9 129.5 62.2C129.5 62.5 129.4 62.7 129.3 62.9C129.2 63.1 129 63.2 128.7 63.2H123.1V66.8H126.1C126.3 66.8 126.5 66.9 126.7 67.1C126.8 67.3 126.9 67.5 126.9 67.8C126.9 68 126.8 68.2 126.7 68.4C126.6 68.6 126.4 68.7 126.1 68.7H123.1V72.3H128.7C128.9 72.3 129.1 72.4 129.3 72.6C129.4 72.8 129.5 73.1 129.5 73.3C129.5 73.6 129.4 73.8 129.3 74C129.2 74.2 129 74.3 128.7 74.3H121.7C121.4 74.3 121.1 74.2 120.9 74.1C120.7 74 120.6 73.9 120.6 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M137.5 73.6V61.9C137.5 61.7 137.6 61.5 137.8 61.4C138 61.3 138.3 61.2 138.6 61.2H145.6C145.9 61.2 146.1 61.3 146.2 61.5C146.3 61.7 146.4 61.9 146.4 62.2C146.4 62.5 146.3 62.7 146.2 62.9C146.1 63.1 145.9 63.2 145.6 63.2H140V66.8H143C143.2 66.8 143.4 66.9 143.6 67.1C143.7 67.3 143.8 67.5 143.8 67.8C143.8 68 143.7 68.2 143.6 68.4C143.5 68.6 143.3 68.7 143 68.7H140V72.3H145.6C145.8 72.3 146 72.4 146.2 72.6C146.3 72.8 146.4 73.1 146.4 73.3C146.4 73.6 146.3 73.8 146.2 74C146.1 74.2 145.9 74.3 145.6 74.3H138.6C138.3 74.3 138 74.2 137.8 74.1C137.6 74 137.5 73.9 137.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M149.2 73.6V61.9C149.2 61.7 149.3 61.5 149.6 61.3C149.8 61.2 150.2 61.1 150.5 61.1C151 61.1 151.3 61.2 151.6 61.3C151.9 61.5 152.1 61.8 152.4 62.3L156.2 69.1V61.8C156.2 61.6 156.3 61.4 156.6 61.3C156.8 61.2 157.2 61.1 157.5 61.1C157.9 61.1 158.2 61.2 158.4 61.3C158.6 61.4 158.8 61.6 158.8 61.8V73.6C158.8 73.8 158.7 74 158.4 74.1C158.1 74.2 157.8 74.3 157.5 74.3C156.8 74.3 156.3 74.1 156 73.6L151.8 66.3V73.6C151.8 73.8 151.7 74 151.4 74.1C151.1 74.2 150.8 74.3 150.5 74.3C150.1 74.3 149.8 74.2 149.6 74.1C149.3 74 149.2 73.9 149.2 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M167.6 73.6V61.9C167.6 61.7 167.7 61.5 167.8 61.4C168 61.3 168.2 61.2 168.4 61.2H172.3C175.4 61.2 177 62.5 177 65C177 66 176.8 66.8 176.3 67.4C175.8 68 175.2 68.4 174.4 68.6L177 73C177.1 73.1 177.1 73.2 177.1 73.3C177.1 73.6 176.9 73.9 176.6 74.1C176.3 74.4 175.9 74.5 175.5 74.5C175.1 74.5 174.8 74.3 174.6 73.9L171.8 68.9H170.1V73.6C170.1 73.8 170 74 169.7 74.1C169.4 74.2 169.1 74.3 168.8 74.3C168.5 74.3 168.2 74.2 167.9 74.1C167.7 74 167.6 73.9 167.6 73.6ZM170.1 67.1H172.3C173.7 67.1 174.4 66.5 174.4 65.2C174.4 63.9 173.7 63.3 172.3 63.3H170.1V67.1Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M180 73.6V61.9C180 61.7 180.1 61.5 180.3 61.4C180.5 61.3 180.8 61.2 181.1 61.2H188.1C188.4 61.2 188.6 61.3 188.7 61.5C188.8 61.7 188.9 61.9 188.9 62.2C188.9 62.5 188.8 62.7 188.7 62.9C188.6 63.1 188.4 63.2 188.1 63.2H182.5V66.8H185.5C185.7 66.8 185.9 66.9 186.1 67.1C186.2 67.3 186.3 67.5 186.3 67.8C186.3 68 186.2 68.2 186.1 68.4C186 68.6 185.8 68.7 185.5 68.7H182.5V72.3H188.1C188.3 72.3 188.5 72.4 188.7 72.6C188.8 72.8 188.9 73.1 188.9 73.3C188.9 73.6 188.8 73.8 188.7 74C188.6 74.2 188.4 74.3 188.1 74.3H181.1C180.8 74.3 180.5 74.2 180.3 74.1C180.2 74 180 73.9 180 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191 72.3C191 72 191.1 71.7 191.3 71.5C191.5 71.2 191.8 71.1 192.1 71.1C192.3 71.1 192.5 71.2 192.7 71.3C192.9 71.5 193.1 71.6 193.3 71.8C193.5 72 193.8 72.1 194.2 72.3C194.6 72.4 195 72.5 195.5 72.5C196.1 72.5 196.7 72.4 197.1 72.1C197.5 71.8 197.7 71.4 197.7 70.9C197.7 70.5 197.6 70.2 197.4 69.9C197.2 69.6 196.9 69.4 196.5 69.2C196.1 69 195.7 68.8 195.3 68.7C194.9 68.5 194.4 68.3 193.9 68.1C193.4 67.9 193 67.6 192.7 67.4C192.3 67.1 192 66.8 191.8 66.3C191.6 65.8 191.5 65.3 191.5 64.8C191.5 64.1 191.6 63.6 191.9 63.1C192.2 62.6 192.6 62.2 193 61.9C193.5 61.6 194 61.4 194.5 61.3C195 61.2 195.6 61.1 196.2 61.1C196.5 61.1 196.9 61.1 197.3 61.2C197.7 61.2 198.1 61.3 198.5 61.4C198.9 61.5 199.3 61.7 199.5 61.9C199.8 62.1 199.9 62.4 199.9 62.6C199.9 62.9 199.8 63.1 199.6 63.4C199.4 63.7 199.2 63.8 198.8 63.8C198.7 63.8 198.4 63.7 197.8 63.5C197.3 63.3 196.7 63.2 196 63.2C195.3 63.2 194.7 63.3 194.4 63.6C194 63.8 193.8 64.2 193.8 64.6C193.8 65 194 65.3 194.3 65.5C194.6 65.8 195 66 195.5 66.1C196 66.3 196.5 66.5 197 66.7C197.5 66.9 198.1 67.2 198.5 67.5C199 67.8 199.4 68.2 199.7 68.8C200 69.3 200.2 70 200.2 70.7C200.2 71.9 199.8 72.9 198.9 73.6C198 74.3 196.9 74.6 195.5 74.6C194.2 74.6 193.2 74.4 192.3 73.9C191.5 73.4 191 72.9 191 72.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M203.5 73.6V61.9C203.5 61.7 203.6 61.5 203.8 61.4C204 61.3 204.3 61.2 204.6 61.2H211.6C211.9 61.2 212.1 61.3 212.2 61.5C212.3 61.7 212.4 61.9 212.4 62.2C212.4 62.5 212.3 62.7 212.2 62.9C212.1 63.1 211.9 63.2 211.6 63.2H206V66.8H209C209.2 66.8 209.4 66.9 209.6 67.1C209.7 67.3 209.8 67.5 209.8 67.8C209.8 68 209.7 68.2 209.6 68.4C209.5 68.6 209.3 68.7 209 68.7H206V72.3H211.6C211.8 72.3 212 72.4 212.2 72.6C212.3 72.8 212.4 73.1 212.4 73.3C212.4 73.6 212.3 73.8 212.2 74C212.1 74.2 211.9 74.3 211.6 74.3H204.6C204.3 74.3 204 74.2 203.8 74.1C203.6 74 203.5 73.9 203.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M227.4 70.3V61.8C227.4 61.6 227.5 61.4 227.8 61.3C228 61.2 228.4 61.1 228.7 61.1C229.1 61.1 229.4 61.2 229.6 61.3C229.8 61.4 230 61.6 230 61.8V70.3C230 71.7 230.7 72.4 232.2 72.4C233.6 72.4 234.4 71.7 234.4 70.3V61.8C234.4 61.6 234.5 61.4 234.8 61.3C235.1 61.2 235.4 61.1 235.7 61.1C236.1 61.1 236.4 61.2 236.6 61.3C236.8 61.4 237 61.6 237 61.8V70.3C237 71.7 236.6 72.8 235.7 73.4C234.8 74.1 233.7 74.4 232.3 74.4C230.9 74.4 229.7 74.1 228.9 73.4C227.8 72.8 227.4 71.8 227.4 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M9.49999 61.4C9.49999 61.4 9.79999 61 10.2 61.1C10.2 61.1 11 61.3 11.2 61.6C11.2 61.6 11.4 61.9 11.2 62.2L9.99999 65C9.99999 65 9.79999 65.3 9.19999 65.3C9.19999 65.3 8.89999 65.3 8.59999 65C8.59999 65 8.19999 64.6 8.39999 64.2L9.49999 61.4Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M222.1 71.5L222.8 73.9C222.9 74.2 223.2 74.4 223.8 74.4C224.2 74.4 224.4 74.3 224.8 74.1C225.2 73.9 225.4 73.7 225.4 73.4C225.4 73.4 225.4 73.3 225.4 73.2L221.6 62C221.5 61.7 221.3 61.5 221 61.3C220.6 61.1 220 61.1 219.6 61.1C219.2 61.1 218.6 61.1 218.2 61.3C217.9 61.5 217.7 61.7 217.6 62L213.8 73.2C213.8 73.3 213.8 73.4 213.8 73.4C213.8 73.7 214 73.9 214.4 74.1C214.8 74.3 215 74.4 215.4 74.4C215.9 74.4 216.3 74.2 216.4 73.9L217.1 71.5H222.1ZM217.8 69.6L219.7 63.4L221.6 69.6H217.8Z"
					fill={darkPrimaryColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="236.9" height="74.5" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

const Icon = ({ primaryColor, darkPrimaryColor, secondaryColor }) => {
	return (
		<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path
					d="M68.2 83.9H45.2C29.9 83.9 17.4 72.5 17.4 58.4V56.1H0V95.8H68.2H75.5H77.5H77.8H79C83.4 95.8 87.4 94 90.4 91C92.5 89.1 94.1 86.6 95 83.9H94.8H68.2Z"
					fill={secondaryColor}
				/>
				<path
					d="M77.8 0H75.5H20.4H18C8.1 0 0 7.5 0 16.7V18.3V43H57.7C61.2 43 64.1 40.4 64.1 37.2V30.9C64.1 30.7 64.1 30.5 64.1 30.3C63.8 27.3 61.1 25 57.7 25H38.1C34.6 25 31.7 27.6 31.7 30.8V33.2V34.7C31.7 36.1 30.7 37.3 29.4 37.3H19.7C19.1 37.3 18.5 37 18.1 36.6C17.7 36.1 17.4 35.5 17.4 34.8V28.6C17.4 20.8 23.1 14 31.2 12.2C32.6 12 34 12 35.4 12H60.5C70.4 12 78.5 19.5 78.5 28.7V39.3C78.5 40.5 78.4 41.6 78.1 42.7C76.4 50.3 69.1 56.1 60.4 56.1H31.7V58.4C31.7 63.2 34.6 67.3 38.8 69.4C40.7 70.4 42.9 70.9 45.2 70.9H68.2H95.8V52.9V18.3V16.7C95.8 7.5 87.7 0 77.8 0Z"
					fill={secondaryColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="95.8" height="95.8" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

const Dark = ({ primaryColor, darkPrimaryColor, secondaryColor }) => {
	return (
		<svg width="237" height="75" viewBox="0 0 237 75" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path
					d="M108.9 39.2C104 39.2 99.9 35.5 99.9 30.9V29.4H119C125.6 29.4 131 24.4 131 18.3V11.2C131 5 125.7 0 119.1 0H102.4C101.5 0 100.5 0 99.6 0.2C94.2 1.4 90.4 6 90.4 11.1V15.2C90.4 16.1 91.1 16.9 91.9 16.9H98.4C99.2 16.9 99.9 16.1 99.9 15.2V14.2V12.6C99.9 10.5 101.8 8.7 104.1 8.7H117.2C119.5 8.7 121.4 10.4 121.4 12.6V16.8C121.4 18.9 119.5 20.7 117.2 20.7H84.9V29.4H90.3V30.9C90.3 40.3 98.6 47.9 108.8 47.9H136.4V39.2H108.9Z"
					fill={secondaryColor}
				/>
				<path
					d="M66.4 8.7C71.3 8.7 75.4 12.4 75.4 17V46.3C75.4 47.1 76.1999 47.8 77.0999 47.8H83.1999C84.0999 47.8 84.9 47.1 84.9 46.3V17.1C84.9 7.7 76.6 0 66.4 0H46.7C45.9 0 45.2 0.8 45.2 1.7V46.2C45.2 47.1 45.9 47.9 46.7 47.9H53.2C54 47.9 54.7 47.1 54.7 46.2V8.7H66.4Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M12.9 8.7H38C38.9 8.7 39.7 8 39.7 7.2V1.5C39.7 0.7 39 0 38.1 0H12.8C5.7 0 0 5.3 0 11.8V36.1C0 42.6 5.7 47.9 12.8 47.9H38.1C39 47.9 39.8 47.2 39.8 46.4V40.7C39.8 39.9 39.1 39.2 38.1 39.2H12.9C11.1 39.2 9.5 37.7 9.5 36V29.3H24.5C25.4 29.3 26.2 28.6 26.2 27.8V22.2C26.2 21.4 25.5 20.7 24.5 20.7H9.5V12C9.5 10.2 11.1 8.7 12.9 8.7Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M236.9 31.4C236.9 31 236.9 30.5 236.9 30C236.4 24.7 231 20.7 225.2 20.7H210.9H210.5C208.2 20.7 206.3 18.7 206.3 16.2V12.9C206.3 10.6 208.3 8.7 210.8 8.7H216.5H216.6H230.9C231.6 8.7 232.3 8.3 232.5 7.6C232.6 7.4 232.6 7.3 232.6 7.1V1.5C232.6 0.7 231.8 0 230.9 0H208.6C202.1 0 196.8 4.9 196.8 10.9V17.7C196.8 24.2 201.7 29.4 207.7 29.4H223.3C225.6 29.4 227.4 31.4 227.4 33.9V35C227.4 37.3 225.4 39.2 222.9 39.2H198.5C197.8 39.2 197.1 39.6 196.9 40.3C196.8 40.5 196.8 40.6 196.8 40.8V46.5C196.8 47.3 197.6 48 198.5 48H218.5H225.3C231.8 48 237 43.1 237 37.1V31.4H236.9Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M158.8 0H138C137.2 0 136.5 0.8 136.5 1.7V29.9C136.5 30.8 137.2 31.6 138 31.6H144.5C145.3 31.6 146 30.8 146 29.9V8.8H157.7C162.6 8.8 166.7 12.5 166.7 17.1V32.5C166.7 37.1 162.7 39.2 157.7 39.2H136.5V47.9H157.7C167.9 47.9 176.2 41.9 176.2 32.5V17C176.3 7.9 168.7 0 158.8 0Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191.3 30.2V14.2C191.3 13.3 190.6 12.5 189.8 12.5H183.3C182.5 12.5 181.8 13.3 181.8 14.2V30.2V46.2C181.8 47.1 182.5 47.9 183.3 47.9H189.8C190.6 47.9 191.3 47.1 191.3 46.2V30.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191.3 3.6V1.7C191.3 0.8 190.6 0 189.8 0H183.3C182.5 0 181.8 0.8 181.8 1.7V3.6V7C181.8 7.9 182.5 8.7 183.3 8.7H189.8C190.6 8.7 191.3 7.9 191.3 7V3.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M0 73.6V61.9C0 61.7 0.1 61.5 0.4 61.4C0.6 61.3 1 61.2 1.3 61.2C1.7 61.2 2 61.3 2.2 61.4C2.4 61.5 2.6 61.7 2.6 61.9V72.4H7.4C7.6 72.4 7.8 72.5 8 72.7C8.1 72.9 8.2 73.1 8.2 73.4C8.2 73.7 8.1 73.9 8 74.1C7.9 74.3 7.7 74.4 7.4 74.4H1.1C0.8 74.4 0.5 74.3 0.3 74.2C0.1 74 0 73.9 0 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M14.2 73.6V61.9C14.2 61.7 14.3 61.5 14.5 61.4C14.7 61.3 15 61.2 15.3 61.2H22.3C22.6 61.2 22.8 61.3 22.9 61.5C23 61.7 23.1 61.9 23.1 62.2C23.1 62.5 23 62.7 22.9 62.9C22.8 63.1 22.6 63.2 22.3 63.2H16.7V66.8H19.7C19.9 66.8 20.1 66.9 20.3 67.1C20.4 67.3 20.5 67.5 20.5 67.8C20.5 68 20.4 68.2 20.3 68.4C20.2 68.6 20 68.7 19.7 68.7H16.7V72.3H22.3C22.5 72.3 22.7 72.4 22.9 72.6C23 72.8 23.1 73.1 23.1 73.3C23.1 73.6 23 73.8 22.9 74C22.8 74.2 22.6 74.3 22.3 74.3H15.3C15 74.3 14.7 74.2 14.5 74.1C14.3 74 14.2 73.9 14.2 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M25.9 73.6V61.9C25.9 61.7 26 61.5 26.3 61.4C26.5 61.3 26.9 61.2 27.2 61.2C27.6 61.2 27.9 61.3 28.1 61.4C28.3 61.5 28.5 61.7 28.5 61.9V72.4H33.3C33.5 72.4 33.7 72.5 33.9 72.7C34 72.9 34.1 73.1 34.1 73.4C34.1 73.7 34 73.9 33.9 74.1C33.8 74.3 33.6 74.4 33.3 74.4H27C26.7 74.4 26.4 74.3 26.2 74.2C26 74 25.9 73.9 25.9 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M36.7 73.6V61.9C36.7 61.7 36.8 61.5 37 61.4C37.2 61.3 37.4999 61.2 37.7999 61.2H44.7999C45.0999 61.2 45.3 61.3 45.4 61.5C45.5 61.7 45.5999 61.9 45.5999 62.2C45.5999 62.5 45.5 62.7 45.4 62.9C45.3 63.1 45.0999 63.2 44.7999 63.2H39.2V66.8H42.2C42.4 66.8 42.5999 66.9 42.7999 67.1C42.8999 67.3 43 67.5 43 67.8C43 68 42.8999 68.2 42.7999 68.4C42.6999 68.6 42.5 68.7 42.2 68.7H39.2V72.3H44.7999C44.9999 72.3 45.2 72.4 45.4 72.6C45.5 72.8 45.5999 73.1 45.5999 73.3C45.5999 73.6 45.5 73.8 45.4 74C45.3 74.2 45.0999 74.3 44.7999 74.3H37.7999C37.4999 74.3 37.2 74.2 37 74.1C36.8 74 36.7 73.9 36.7 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M48.1 70.3V65.2C48.1 63.8 48.5 62.7 49.4 62.1C50.2 61.4 51.3 61.1 52.7 61.1C54.1 61.1 55.2 61.4 56.1 62C57 62.6 57.4 63.5 57.4 64.5C57.4 64.9 57.3 65.3 57.1 65.4C56.9 65.6 56.6 65.6 56.1 65.6C55.3 65.6 54.8 65.4 54.8 64.9C54.8 64.7 54.8 64.6 54.8 64.5C54.8 64.4 54.7 64.2 54.6 64C54.5 63.8 54.4 63.7 54.3 63.5C54.2 63.4 54 63.3 53.7 63.2C53.4 63.1 53.1 63.1 52.8 63.1C51.4 63.1 50.7 63.8 50.7 65.2V70.3C50.7 71.7 51.4 72.4 52.8 72.4C53.2 72.4 53.6 72.3 53.9 72.2C54.2 72.1 54.4 71.9 54.5 71.7C54.6 71.5 54.7 71.3 54.8 71.1C54.8 70.9 54.9 70.7 54.9 70.5C54.9 70 55.4 69.8 56.2 69.8C56.7 69.8 57 69.9 57.2 70C57.4 70.2 57.5 70.5 57.5 70.9C57.5 72 57.1 72.9 56.2 73.5C55.3 74.2 54.2 74.5 52.8 74.5C51.4 74.5 50.3 74.2 49.5 73.5C48.5 72.8 48.1 71.8 48.1 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M59.4 62.2C59.4 61.9 59.5 61.7 59.6 61.5C59.7 61.3 59.9 61.2 60.2 61.2H68.6C68.9 61.2 69.1 61.3 69.2 61.5C69.3 61.7 69.4 61.9 69.4 62.2C69.4 62.5 69.3 62.7 69.2 62.9C69.1 63.1 68.9 63.2 68.6 63.2H65.7V73.6C65.7 73.8 65.6 74 65.3 74.1C65 74.2 64.7 74.3 64.4 74.3C64 74.3 63.7 74.2 63.5 74.1C63.2 74 63.1 73.8 63.1 73.6V63.2H60.2C59.9 63.2 59.8 63.1 59.6 62.9C59.4 62.7 59.4 62.4 59.4 62.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M72 73.6V61.9C72 61.7 72.1 61.5 72.2 61.4C72.4 61.3 72.6 61.2 72.8 61.2H76.7C79.8 61.2 81.4 62.5 81.4 65C81.4 66 81.2 66.8 80.7 67.4C80.2 68 79.6 68.4 78.8 68.6L81.4 73C81.5 73.1 81.5 73.2 81.5 73.3C81.5 73.6 81.3 73.9 81 74.1C80.7 74.4 80.3 74.5 79.9 74.5C79.5 74.5 79.2 74.3 79 73.9L76.2 68.9H74.5V73.6C74.5 73.8 74.4 74 74.1 74.1C73.8 74.2 73.5 74.3 73.2 74.3C72.9 74.3 72.6 74.2 72.3 74.1C72.1 74 72 73.9 72 73.6ZM74.6 67.1H76.8C78.2 67.1 78.9 66.5 78.9 65.2C78.9 63.9 78.2 63.3 76.8 63.3H74.6V67.1Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M84.5 73.6V61.9C84.5 61.7 84.6 61.5 84.9 61.4C85.1 61.3 85.5 61.2 85.8 61.2C86.2 61.2 86.5 61.3 86.7 61.4C86.9 61.5 87.1 61.7 87.1 61.9V73.7C87.1 73.9 87 74.1 86.7 74.2C86.4 74.3 86.1 74.4 85.8 74.4C85.4 74.4 85.1 74.3 84.9 74.2C84.6 74 84.5 73.9 84.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M90.5 70.3V65.2C90.5 63.8 90.9 62.7 91.8 62.1C92.6 61.4 93.7 61.1 95.1 61.1C96.5 61.1 97.6 61.4 98.5 62C99.4 62.6 99.8 63.5 99.8 64.5C99.8 64.9 99.7 65.3 99.5 65.4C99.3 65.6 99 65.6 98.5 65.6C97.7 65.6 97.2 65.4 97.2 64.9C97.2 64.7 97.2 64.6 97.2 64.5C97.2 64.4 97.1 64.2 97 64C96.9 63.8 96.8 63.7 96.7 63.5C96.6 63.4 96.4 63.3 96.1 63.2C95.8 63.1 95.5 63.1 95.2 63.1C93.8 63.1 93.1 63.8 93.1 65.2V70.3C93.1 71.7 93.8 72.4 95.2 72.4C95.6 72.4 96 72.3 96.3 72.2C96.6 72.1 96.8 71.9 96.9 71.7C97 71.5 97.1 71.3 97.2 71.1C97.2 70.9 97.3 70.7 97.3 70.5C97.3 70 97.8 69.8 98.6 69.8C99.1 69.8 99.4 69.9 99.6 70C99.8 70.2 99.9 70.5 99.9 70.9C99.9 72 99.5 72.9 98.6 73.5C97.7 74.2 96.6 74.5 95.2 74.5C93.8 74.5 92.7 74.2 91.9 73.5C90.9 72.8 90.5 71.8 90.5 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M102.8 73.6V61.9C102.8 61.7 102.9 61.5 103.2 61.4C103.4 61.3 103.8 61.2 104.1 61.2C104.5 61.2 104.8 61.3 105 61.4C105.2 61.5 105.4 61.7 105.4 61.9V73.7C105.4 73.9 105.3 74.1 105 74.2C104.7 74.3 104.4 74.4 104.1 74.4C103.7 74.4 103.4 74.3 103.2 74.2C102.9 74 102.8 73.9 102.8 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M108 62.2C108 61.9 108.1 61.7 108.2 61.5C108.3 61.3 108.5 61.2 108.8 61.2H117.2C117.5 61.2 117.7 61.3 117.8 61.5C117.9 61.7 118 61.9 118 62.2C118 62.5 117.9 62.7 117.8 62.9C117.7 63.1 117.5 63.2 117.2 63.2H114.3V73.6C114.3 73.8 114.2 74 113.9 74.1C113.6 74.2 113.3 74.3 113 74.3C112.6 74.3 112.3 74.2 112.1 74.1C111.8 74 111.7 73.8 111.7 73.6V63.2H108.8C108.5 63.2 108.4 63.1 108.2 62.9C108.1 62.7 108 62.4 108 62.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M120.6 73.6V61.9C120.6 61.7 120.7 61.5 120.9 61.4C121.1 61.3 121.4 61.2 121.7 61.2H128.7C129 61.2 129.2 61.3 129.3 61.5C129.4 61.7 129.5 61.9 129.5 62.2C129.5 62.5 129.4 62.7 129.3 62.9C129.2 63.1 129 63.2 128.7 63.2H123.1V66.8H126.1C126.3 66.8 126.5 66.9 126.7 67.1C126.8 67.3 126.9 67.5 126.9 67.8C126.9 68 126.8 68.2 126.7 68.4C126.6 68.6 126.4 68.7 126.1 68.7H123.1V72.3H128.7C128.9 72.3 129.1 72.4 129.3 72.6C129.4 72.8 129.5 73.1 129.5 73.3C129.5 73.6 129.4 73.8 129.3 74C129.2 74.2 129 74.3 128.7 74.3H121.7C121.4 74.3 121.1 74.2 120.9 74.1C120.7 74 120.6 73.9 120.6 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M137.5 73.6V61.9C137.5 61.7 137.6 61.5 137.8 61.4C138 61.3 138.3 61.2 138.6 61.2H145.6C145.9 61.2 146.1 61.3 146.2 61.5C146.3 61.7 146.4 61.9 146.4 62.2C146.4 62.5 146.3 62.7 146.2 62.9C146.1 63.1 145.9 63.2 145.6 63.2H140V66.8H143C143.2 66.8 143.4 66.9 143.6 67.1C143.7 67.3 143.8 67.5 143.8 67.8C143.8 68 143.7 68.2 143.6 68.4C143.5 68.6 143.3 68.7 143 68.7H140V72.3H145.6C145.8 72.3 146 72.4 146.2 72.6C146.3 72.8 146.4 73.1 146.4 73.3C146.4 73.6 146.3 73.8 146.2 74C146.1 74.2 145.9 74.3 145.6 74.3H138.6C138.3 74.3 138 74.2 137.8 74.1C137.6 74 137.5 73.9 137.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M149.2 73.6V61.9C149.2 61.7 149.3 61.5 149.6 61.3C149.8 61.2 150.2 61.1 150.5 61.1C151 61.1 151.3 61.2 151.6 61.3C151.9 61.5 152.1 61.8 152.4 62.3L156.2 69.1V61.8C156.2 61.6 156.3 61.4 156.6 61.3C156.8 61.2 157.2 61.1 157.5 61.1C157.9 61.1 158.2 61.2 158.4 61.3C158.6 61.4 158.8 61.6 158.8 61.8V73.6C158.8 73.8 158.7 74 158.4 74.1C158.1 74.2 157.8 74.3 157.5 74.3C156.8 74.3 156.3 74.1 156 73.6L151.8 66.3V73.6C151.8 73.8 151.7 74 151.4 74.1C151.1 74.2 150.8 74.3 150.5 74.3C150.1 74.3 149.8 74.2 149.6 74.1C149.3 74 149.2 73.9 149.2 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M167.6 73.6V61.9C167.6 61.7 167.7 61.5 167.8 61.4C168 61.3 168.2 61.2 168.4 61.2H172.3C175.4 61.2 177 62.5 177 65C177 66 176.8 66.8 176.3 67.4C175.8 68 175.2 68.4 174.4 68.6L177 73C177.1 73.1 177.1 73.2 177.1 73.3C177.1 73.6 176.9 73.9 176.6 74.1C176.3 74.4 175.9 74.5 175.5 74.5C175.1 74.5 174.8 74.3 174.6 73.9L171.8 68.9H170.1V73.6C170.1 73.8 170 74 169.7 74.1C169.4 74.2 169.1 74.3 168.8 74.3C168.5 74.3 168.2 74.2 167.9 74.1C167.7 74 167.6 73.9 167.6 73.6ZM170.1 67.1H172.3C173.7 67.1 174.4 66.5 174.4 65.2C174.4 63.9 173.7 63.3 172.3 63.3H170.1V67.1Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M180 73.6V61.9C180 61.7 180.1 61.5 180.3 61.4C180.5 61.3 180.8 61.2 181.1 61.2H188.1C188.4 61.2 188.6 61.3 188.7 61.5C188.8 61.7 188.9 61.9 188.9 62.2C188.9 62.5 188.8 62.7 188.7 62.9C188.6 63.1 188.4 63.2 188.1 63.2H182.5V66.8H185.5C185.7 66.8 185.9 66.9 186.1 67.1C186.2 67.3 186.3 67.5 186.3 67.8C186.3 68 186.2 68.2 186.1 68.4C186 68.6 185.8 68.7 185.5 68.7H182.5V72.3H188.1C188.3 72.3 188.5 72.4 188.7 72.6C188.8 72.8 188.9 73.1 188.9 73.3C188.9 73.6 188.8 73.8 188.7 74C188.6 74.2 188.4 74.3 188.1 74.3H181.1C180.8 74.3 180.5 74.2 180.3 74.1C180.2 74 180 73.9 180 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191 72.3C191 72 191.1 71.7 191.3 71.5C191.5 71.2 191.8 71.1 192.1 71.1C192.3 71.1 192.5 71.2 192.7 71.3C192.9 71.5 193.1 71.6 193.3 71.8C193.5 72 193.8 72.1 194.2 72.3C194.6 72.4 195 72.5 195.5 72.5C196.1 72.5 196.7 72.4 197.1 72.1C197.5 71.8 197.7 71.4 197.7 70.9C197.7 70.5 197.6 70.2 197.4 69.9C197.2 69.6 196.9 69.4 196.5 69.2C196.1 69 195.7 68.8 195.3 68.7C194.9 68.5 194.4 68.3 193.9 68.1C193.4 67.9 193 67.6 192.7 67.4C192.3 67.1 192 66.8 191.8 66.3C191.6 65.8 191.5 65.3 191.5 64.8C191.5 64.1 191.6 63.6 191.9 63.1C192.2 62.6 192.6 62.2 193 61.9C193.5 61.6 194 61.4 194.5 61.3C195 61.2 195.6 61.1 196.2 61.1C196.5 61.1 196.9 61.1 197.3 61.2C197.7 61.2 198.1 61.3 198.5 61.4C198.9 61.5 199.3 61.7 199.5 61.9C199.8 62.1 199.9 62.4 199.9 62.6C199.9 62.9 199.8 63.1 199.6 63.4C199.4 63.7 199.2 63.8 198.8 63.8C198.7 63.8 198.4 63.7 197.8 63.5C197.3 63.3 196.7 63.2 196 63.2C195.3 63.2 194.7 63.3 194.4 63.6C194 63.8 193.8 64.2 193.8 64.6C193.8 65 194 65.3 194.3 65.5C194.6 65.8 195 66 195.5 66.1C196 66.3 196.5 66.5 197 66.7C197.5 66.9 198.1 67.2 198.5 67.5C199 67.8 199.4 68.2 199.7 68.8C200 69.3 200.2 70 200.2 70.7C200.2 71.9 199.8 72.9 198.9 73.6C198 74.3 196.9 74.6 195.5 74.6C194.2 74.6 193.2 74.4 192.3 73.9C191.5 73.4 191 72.9 191 72.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M203.5 73.6V61.9C203.5 61.7 203.6 61.5 203.8 61.4C204 61.3 204.3 61.2 204.6 61.2H211.6C211.9 61.2 212.1 61.3 212.2 61.5C212.3 61.7 212.4 61.9 212.4 62.2C212.4 62.5 212.3 62.7 212.2 62.9C212.1 63.1 211.9 63.2 211.6 63.2H206V66.8H209C209.2 66.8 209.4 66.9 209.6 67.1C209.7 67.3 209.8 67.5 209.8 67.8C209.8 68 209.7 68.2 209.6 68.4C209.5 68.6 209.3 68.7 209 68.7H206V72.3H211.6C211.8 72.3 212 72.4 212.2 72.6C212.3 72.8 212.4 73.1 212.4 73.3C212.4 73.6 212.3 73.8 212.2 74C212.1 74.2 211.9 74.3 211.6 74.3H204.6C204.3 74.3 204 74.2 203.8 74.1C203.6 74 203.5 73.9 203.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M227.4 70.3V61.8C227.4 61.6 227.5 61.4 227.8 61.3C228 61.2 228.4 61.1 228.7 61.1C229.1 61.1 229.4 61.2 229.6 61.3C229.8 61.4 230 61.6 230 61.8V70.3C230 71.7 230.7 72.4 232.2 72.4C233.6 72.4 234.4 71.7 234.4 70.3V61.8C234.4 61.6 234.5 61.4 234.8 61.3C235.1 61.2 235.4 61.1 235.7 61.1C236.1 61.1 236.4 61.2 236.6 61.3C236.8 61.4 237 61.6 237 61.8V70.3C237 71.7 236.6 72.8 235.7 73.4C234.8 74.1 233.7 74.4 232.3 74.4C230.9 74.4 229.7 74.1 228.9 73.4C227.8 72.8 227.4 71.8 227.4 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M9.49999 61.4C9.49999 61.4 9.79999 61 10.2 61.1C10.2 61.1 11 61.3 11.2 61.6C11.2 61.6 11.4 61.9 11.2 62.2L9.99999 65C9.99999 65 9.79999 65.3 9.19999 65.3C9.19999 65.3 8.89999 65.3 8.59999 65C8.59999 65 8.19999 64.6 8.39999 64.2L9.49999 61.4Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M222.1 71.5L222.8 73.9C222.9 74.2 223.2 74.4 223.8 74.4C224.2 74.4 224.4 74.3 224.8 74.1C225.2 73.9 225.4 73.7 225.4 73.4C225.4 73.4 225.4 73.3 225.4 73.2L221.6 62C221.5 61.7 221.3 61.5 221 61.3C220.6 61.1 220 61.1 219.6 61.1C219.2 61.1 218.6 61.1 218.2 61.3C217.9 61.5 217.7 61.7 217.6 62L213.8 73.2C213.8 73.3 213.8 73.4 213.8 73.4C213.8 73.7 214 73.9 214.4 74.1C214.8 74.3 215 74.4 215.4 74.4C215.9 74.4 216.3 74.2 216.4 73.9L217.1 71.5H222.1ZM217.8 69.6L219.7 63.4L221.6 69.6H217.8Z"
					fill={darkPrimaryColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="236.9" height="74.5" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

const Header = ({ primaryColor, darkPrimaryColor, secondaryColor }) => {
	return (
		<svg width="237" height="75" viewBox="0 0 237 75" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path
					d="M108.9 39.2C104 39.2 99.9 35.5 99.9 30.9V29.4H119C125.6 29.4 131 24.4 131 18.3V11.2C131 5 125.7 0 119.1 0H102.4C101.5 0 100.5 0 99.6 0.2C94.2 1.4 90.4 6 90.4 11.1V15.2C90.4 16.1 91.1 16.9 91.9 16.9H98.4C99.2 16.9 99.9 16.1 99.9 15.2V14.2V12.6C99.9 10.5 101.8 8.7 104.1 8.7H117.2C119.5 8.7 121.4 10.4 121.4 12.6V16.8C121.4 18.9 119.5 20.7 117.2 20.7H84.9V29.4H90.3V30.9C90.3 40.3 98.6 47.9 108.8 47.9H136.4V39.2H108.9Z"
					fill={secondaryColor}
				/>
				<path
					d="M66.4 8.7C71.3 8.7 75.4 12.4 75.4 17V46.3C75.4 47.1 76.1999 47.8 77.0999 47.8H83.1999C84.0999 47.8 84.9 47.1 84.9 46.3V17.1C84.9 7.7 76.6 0 66.4 0H46.7C45.9 0 45.2 0.8 45.2 1.7V46.2C45.2 47.1 45.9 47.9 46.7 47.9H53.2C54 47.9 54.7 47.1 54.7 46.2V8.7H66.4Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M12.9 8.7H38C38.9 8.7 39.7 8 39.7 7.2V1.5C39.7 0.7 39 0 38.1 0H12.8C5.7 0 0 5.3 0 11.8V36.1C0 42.6 5.7 47.9 12.8 47.9H38.1C39 47.9 39.8 47.2 39.8 46.4V40.7C39.8 39.9 39.1 39.2 38.1 39.2H12.9C11.1 39.2 9.5 37.7 9.5 36V29.3H24.5C25.4 29.3 26.2 28.6 26.2 27.8V22.2C26.2 21.4 25.5 20.7 24.5 20.7H9.5V12C9.5 10.2 11.1 8.7 12.9 8.7Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M236.9 31.4C236.9 31 236.9 30.5 236.9 30C236.4 24.7 231 20.7 225.2 20.7H210.9H210.5C208.2 20.7 206.3 18.7 206.3 16.2V12.9C206.3 10.6 208.3 8.7 210.8 8.7H216.5H216.6H230.9C231.6 8.7 232.3 8.3 232.5 7.6C232.6 7.4 232.6 7.3 232.6 7.1V1.5C232.6 0.7 231.8 0 230.9 0H208.6C202.1 0 196.8 4.9 196.8 10.9V17.7C196.8 24.2 201.7 29.4 207.7 29.4H223.3C225.6 29.4 227.4 31.4 227.4 33.9V35C227.4 37.3 225.4 39.2 222.9 39.2H198.5C197.8 39.2 197.1 39.6 196.9 40.3C196.8 40.5 196.8 40.6 196.8 40.8V46.5C196.8 47.3 197.6 48 198.5 48H218.5H225.3C231.8 48 237 43.1 237 37.1V31.4H236.9Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M158.8 0H138C137.2 0 136.5 0.8 136.5 1.7V29.9C136.5 30.8 137.2 31.6 138 31.6H144.5C145.3 31.6 146 30.8 146 29.9V8.8H157.7C162.6 8.8 166.7 12.5 166.7 17.1V32.5C166.7 37.1 162.7 39.2 157.7 39.2H136.5V47.9H157.7C167.9 47.9 176.2 41.9 176.2 32.5V17C176.3 7.9 168.7 0 158.8 0Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191.3 30.2V14.2C191.3 13.3 190.6 12.5 189.8 12.5H183.3C182.5 12.5 181.8 13.3 181.8 14.2V30.2V46.2C181.8 47.1 182.5 47.9 183.3 47.9H189.8C190.6 47.9 191.3 47.1 191.3 46.2V30.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191.3 3.6V1.7C191.3 0.8 190.6 0 189.8 0H183.3C182.5 0 181.8 0.8 181.8 1.7V3.6V7C181.8 7.9 182.5 8.7 183.3 8.7H189.8C190.6 8.7 191.3 7.9 191.3 7V3.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M0 73.6V61.9C0 61.7 0.1 61.5 0.4 61.4C0.6 61.3 1 61.2 1.3 61.2C1.7 61.2 2 61.3 2.2 61.4C2.4 61.5 2.6 61.7 2.6 61.9V72.4H7.4C7.6 72.4 7.8 72.5 8 72.7C8.1 72.9 8.2 73.1 8.2 73.4C8.2 73.7 8.1 73.9 8 74.1C7.9 74.3 7.7 74.4 7.4 74.4H1.1C0.8 74.4 0.5 74.3 0.3 74.2C0.1 74 0 73.9 0 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M14.2 73.6V61.9C14.2 61.7 14.3 61.5 14.5 61.4C14.7 61.3 15 61.2 15.3 61.2H22.3C22.6 61.2 22.8 61.3 22.9 61.5C23 61.7 23.1 61.9 23.1 62.2C23.1 62.5 23 62.7 22.9 62.9C22.8 63.1 22.6 63.2 22.3 63.2H16.7V66.8H19.7C19.9 66.8 20.1 66.9 20.3 67.1C20.4 67.3 20.5 67.5 20.5 67.8C20.5 68 20.4 68.2 20.3 68.4C20.2 68.6 20 68.7 19.7 68.7H16.7V72.3H22.3C22.5 72.3 22.7 72.4 22.9 72.6C23 72.8 23.1 73.1 23.1 73.3C23.1 73.6 23 73.8 22.9 74C22.8 74.2 22.6 74.3 22.3 74.3H15.3C15 74.3 14.7 74.2 14.5 74.1C14.3 74 14.2 73.9 14.2 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M25.9 73.6V61.9C25.9 61.7 26 61.5 26.3 61.4C26.5 61.3 26.9 61.2 27.2 61.2C27.6 61.2 27.9 61.3 28.1 61.4C28.3 61.5 28.5 61.7 28.5 61.9V72.4H33.3C33.5 72.4 33.7 72.5 33.9 72.7C34 72.9 34.1 73.1 34.1 73.4C34.1 73.7 34 73.9 33.9 74.1C33.8 74.3 33.6 74.4 33.3 74.4H27C26.7 74.4 26.4 74.3 26.2 74.2C26 74 25.9 73.9 25.9 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M36.7 73.6V61.9C36.7 61.7 36.8 61.5 37 61.4C37.2 61.3 37.4999 61.2 37.7999 61.2H44.7999C45.0999 61.2 45.3 61.3 45.4 61.5C45.5 61.7 45.5999 61.9 45.5999 62.2C45.5999 62.5 45.5 62.7 45.4 62.9C45.3 63.1 45.0999 63.2 44.7999 63.2H39.2V66.8H42.2C42.4 66.8 42.5999 66.9 42.7999 67.1C42.8999 67.3 43 67.5 43 67.8C43 68 42.8999 68.2 42.7999 68.4C42.6999 68.6 42.5 68.7 42.2 68.7H39.2V72.3H44.7999C44.9999 72.3 45.2 72.4 45.4 72.6C45.5 72.8 45.5999 73.1 45.5999 73.3C45.5999 73.6 45.5 73.8 45.4 74C45.3 74.2 45.0999 74.3 44.7999 74.3H37.7999C37.4999 74.3 37.2 74.2 37 74.1C36.8 74 36.7 73.9 36.7 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M48.1 70.3V65.2C48.1 63.8 48.5 62.7 49.4 62.1C50.2 61.4 51.3 61.1 52.7 61.1C54.1 61.1 55.2 61.4 56.1 62C57 62.6 57.4 63.5 57.4 64.5C57.4 64.9 57.3 65.3 57.1 65.4C56.9 65.6 56.6 65.6 56.1 65.6C55.3 65.6 54.8 65.4 54.8 64.9C54.8 64.7 54.8 64.6 54.8 64.5C54.8 64.4 54.7 64.2 54.6 64C54.5 63.8 54.4 63.7 54.3 63.5C54.2 63.4 54 63.3 53.7 63.2C53.4 63.1 53.1 63.1 52.8 63.1C51.4 63.1 50.7 63.8 50.7 65.2V70.3C50.7 71.7 51.4 72.4 52.8 72.4C53.2 72.4 53.6 72.3 53.9 72.2C54.2 72.1 54.4 71.9 54.5 71.7C54.6 71.5 54.7 71.3 54.8 71.1C54.8 70.9 54.9 70.7 54.9 70.5C54.9 70 55.4 69.8 56.2 69.8C56.7 69.8 57 69.9 57.2 70C57.4 70.2 57.5 70.5 57.5 70.9C57.5 72 57.1 72.9 56.2 73.5C55.3 74.2 54.2 74.5 52.8 74.5C51.4 74.5 50.3 74.2 49.5 73.5C48.5 72.8 48.1 71.8 48.1 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M59.4 62.2C59.4 61.9 59.5 61.7 59.6 61.5C59.7 61.3 59.9 61.2 60.2 61.2H68.6C68.9 61.2 69.1 61.3 69.2 61.5C69.3 61.7 69.4 61.9 69.4 62.2C69.4 62.5 69.3 62.7 69.2 62.9C69.1 63.1 68.9 63.2 68.6 63.2H65.7V73.6C65.7 73.8 65.6 74 65.3 74.1C65 74.2 64.7 74.3 64.4 74.3C64 74.3 63.7 74.2 63.5 74.1C63.2 74 63.1 73.8 63.1 73.6V63.2H60.2C59.9 63.2 59.8 63.1 59.6 62.9C59.4 62.7 59.4 62.4 59.4 62.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M72 73.6V61.9C72 61.7 72.1 61.5 72.2 61.4C72.4 61.3 72.6 61.2 72.8 61.2H76.7C79.8 61.2 81.4 62.5 81.4 65C81.4 66 81.2 66.8 80.7 67.4C80.2 68 79.6 68.4 78.8 68.6L81.4 73C81.5 73.1 81.5 73.2 81.5 73.3C81.5 73.6 81.3 73.9 81 74.1C80.7 74.4 80.3 74.5 79.9 74.5C79.5 74.5 79.2 74.3 79 73.9L76.2 68.9H74.5V73.6C74.5 73.8 74.4 74 74.1 74.1C73.8 74.2 73.5 74.3 73.2 74.3C72.9 74.3 72.6 74.2 72.3 74.1C72.1 74 72 73.9 72 73.6ZM74.6 67.1H76.8C78.2 67.1 78.9 66.5 78.9 65.2C78.9 63.9 78.2 63.3 76.8 63.3H74.6V67.1Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M84.5 73.6V61.9C84.5 61.7 84.6 61.5 84.9 61.4C85.1 61.3 85.5 61.2 85.8 61.2C86.2 61.2 86.5 61.3 86.7 61.4C86.9 61.5 87.1 61.7 87.1 61.9V73.7C87.1 73.9 87 74.1 86.7 74.2C86.4 74.3 86.1 74.4 85.8 74.4C85.4 74.4 85.1 74.3 84.9 74.2C84.6 74 84.5 73.9 84.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M90.5 70.3V65.2C90.5 63.8 90.9 62.7 91.8 62.1C92.6 61.4 93.7 61.1 95.1 61.1C96.5 61.1 97.6 61.4 98.5 62C99.4 62.6 99.8 63.5 99.8 64.5C99.8 64.9 99.7 65.3 99.5 65.4C99.3 65.6 99 65.6 98.5 65.6C97.7 65.6 97.2 65.4 97.2 64.9C97.2 64.7 97.2 64.6 97.2 64.5C97.2 64.4 97.1 64.2 97 64C96.9 63.8 96.8 63.7 96.7 63.5C96.6 63.4 96.4 63.3 96.1 63.2C95.8 63.1 95.5 63.1 95.2 63.1C93.8 63.1 93.1 63.8 93.1 65.2V70.3C93.1 71.7 93.8 72.4 95.2 72.4C95.6 72.4 96 72.3 96.3 72.2C96.6 72.1 96.8 71.9 96.9 71.7C97 71.5 97.1 71.3 97.2 71.1C97.2 70.9 97.3 70.7 97.3 70.5C97.3 70 97.8 69.8 98.6 69.8C99.1 69.8 99.4 69.9 99.6 70C99.8 70.2 99.9 70.5 99.9 70.9C99.9 72 99.5 72.9 98.6 73.5C97.7 74.2 96.6 74.5 95.2 74.5C93.8 74.5 92.7 74.2 91.9 73.5C90.9 72.8 90.5 71.8 90.5 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M102.8 73.6V61.9C102.8 61.7 102.9 61.5 103.2 61.4C103.4 61.3 103.8 61.2 104.1 61.2C104.5 61.2 104.8 61.3 105 61.4C105.2 61.5 105.4 61.7 105.4 61.9V73.7C105.4 73.9 105.3 74.1 105 74.2C104.7 74.3 104.4 74.4 104.1 74.4C103.7 74.4 103.4 74.3 103.2 74.2C102.9 74 102.8 73.9 102.8 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M108 62.2C108 61.9 108.1 61.7 108.2 61.5C108.3 61.3 108.5 61.2 108.8 61.2H117.2C117.5 61.2 117.7 61.3 117.8 61.5C117.9 61.7 118 61.9 118 62.2C118 62.5 117.9 62.7 117.8 62.9C117.7 63.1 117.5 63.2 117.2 63.2H114.3V73.6C114.3 73.8 114.2 74 113.9 74.1C113.6 74.2 113.3 74.3 113 74.3C112.6 74.3 112.3 74.2 112.1 74.1C111.8 74 111.7 73.8 111.7 73.6V63.2H108.8C108.5 63.2 108.4 63.1 108.2 62.9C108.1 62.7 108 62.4 108 62.2Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M120.6 73.6V61.9C120.6 61.7 120.7 61.5 120.9 61.4C121.1 61.3 121.4 61.2 121.7 61.2H128.7C129 61.2 129.2 61.3 129.3 61.5C129.4 61.7 129.5 61.9 129.5 62.2C129.5 62.5 129.4 62.7 129.3 62.9C129.2 63.1 129 63.2 128.7 63.2H123.1V66.8H126.1C126.3 66.8 126.5 66.9 126.7 67.1C126.8 67.3 126.9 67.5 126.9 67.8C126.9 68 126.8 68.2 126.7 68.4C126.6 68.6 126.4 68.7 126.1 68.7H123.1V72.3H128.7C128.9 72.3 129.1 72.4 129.3 72.6C129.4 72.8 129.5 73.1 129.5 73.3C129.5 73.6 129.4 73.8 129.3 74C129.2 74.2 129 74.3 128.7 74.3H121.7C121.4 74.3 121.1 74.2 120.9 74.1C120.7 74 120.6 73.9 120.6 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M137.5 73.6V61.9C137.5 61.7 137.6 61.5 137.8 61.4C138 61.3 138.3 61.2 138.6 61.2H145.6C145.9 61.2 146.1 61.3 146.2 61.5C146.3 61.7 146.4 61.9 146.4 62.2C146.4 62.5 146.3 62.7 146.2 62.9C146.1 63.1 145.9 63.2 145.6 63.2H140V66.8H143C143.2 66.8 143.4 66.9 143.6 67.1C143.7 67.3 143.8 67.5 143.8 67.8C143.8 68 143.7 68.2 143.6 68.4C143.5 68.6 143.3 68.7 143 68.7H140V72.3H145.6C145.8 72.3 146 72.4 146.2 72.6C146.3 72.8 146.4 73.1 146.4 73.3C146.4 73.6 146.3 73.8 146.2 74C146.1 74.2 145.9 74.3 145.6 74.3H138.6C138.3 74.3 138 74.2 137.8 74.1C137.6 74 137.5 73.9 137.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M149.2 73.6V61.9C149.2 61.7 149.3 61.5 149.6 61.3C149.8 61.2 150.2 61.1 150.5 61.1C151 61.1 151.3 61.2 151.6 61.3C151.9 61.5 152.1 61.8 152.4 62.3L156.2 69.1V61.8C156.2 61.6 156.3 61.4 156.6 61.3C156.8 61.2 157.2 61.1 157.5 61.1C157.9 61.1 158.2 61.2 158.4 61.3C158.6 61.4 158.8 61.6 158.8 61.8V73.6C158.8 73.8 158.7 74 158.4 74.1C158.1 74.2 157.8 74.3 157.5 74.3C156.8 74.3 156.3 74.1 156 73.6L151.8 66.3V73.6C151.8 73.8 151.7 74 151.4 74.1C151.1 74.2 150.8 74.3 150.5 74.3C150.1 74.3 149.8 74.2 149.6 74.1C149.3 74 149.2 73.9 149.2 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M167.6 73.6V61.9C167.6 61.7 167.7 61.5 167.8 61.4C168 61.3 168.2 61.2 168.4 61.2H172.3C175.4 61.2 177 62.5 177 65C177 66 176.8 66.8 176.3 67.4C175.8 68 175.2 68.4 174.4 68.6L177 73C177.1 73.1 177.1 73.2 177.1 73.3C177.1 73.6 176.9 73.9 176.6 74.1C176.3 74.4 175.9 74.5 175.5 74.5C175.1 74.5 174.8 74.3 174.6 73.9L171.8 68.9H170.1V73.6C170.1 73.8 170 74 169.7 74.1C169.4 74.2 169.1 74.3 168.8 74.3C168.5 74.3 168.2 74.2 167.9 74.1C167.7 74 167.6 73.9 167.6 73.6ZM170.1 67.1H172.3C173.7 67.1 174.4 66.5 174.4 65.2C174.4 63.9 173.7 63.3 172.3 63.3H170.1V67.1Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M180 73.6V61.9C180 61.7 180.1 61.5 180.3 61.4C180.5 61.3 180.8 61.2 181.1 61.2H188.1C188.4 61.2 188.6 61.3 188.7 61.5C188.8 61.7 188.9 61.9 188.9 62.2C188.9 62.5 188.8 62.7 188.7 62.9C188.6 63.1 188.4 63.2 188.1 63.2H182.5V66.8H185.5C185.7 66.8 185.9 66.9 186.1 67.1C186.2 67.3 186.3 67.5 186.3 67.8C186.3 68 186.2 68.2 186.1 68.4C186 68.6 185.8 68.7 185.5 68.7H182.5V72.3H188.1C188.3 72.3 188.5 72.4 188.7 72.6C188.8 72.8 188.9 73.1 188.9 73.3C188.9 73.6 188.8 73.8 188.7 74C188.6 74.2 188.4 74.3 188.1 74.3H181.1C180.8 74.3 180.5 74.2 180.3 74.1C180.2 74 180 73.9 180 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M191 72.3C191 72 191.1 71.7 191.3 71.5C191.5 71.2 191.8 71.1 192.1 71.1C192.3 71.1 192.5 71.2 192.7 71.3C192.9 71.5 193.1 71.6 193.3 71.8C193.5 72 193.8 72.1 194.2 72.3C194.6 72.4 195 72.5 195.5 72.5C196.1 72.5 196.7 72.4 197.1 72.1C197.5 71.8 197.7 71.4 197.7 70.9C197.7 70.5 197.6 70.2 197.4 69.9C197.2 69.6 196.9 69.4 196.5 69.2C196.1 69 195.7 68.8 195.3 68.7C194.9 68.5 194.4 68.3 193.9 68.1C193.4 67.9 193 67.6 192.7 67.4C192.3 67.1 192 66.8 191.8 66.3C191.6 65.8 191.5 65.3 191.5 64.8C191.5 64.1 191.6 63.6 191.9 63.1C192.2 62.6 192.6 62.2 193 61.9C193.5 61.6 194 61.4 194.5 61.3C195 61.2 195.6 61.1 196.2 61.1C196.5 61.1 196.9 61.1 197.3 61.2C197.7 61.2 198.1 61.3 198.5 61.4C198.9 61.5 199.3 61.7 199.5 61.9C199.8 62.1 199.9 62.4 199.9 62.6C199.9 62.9 199.8 63.1 199.6 63.4C199.4 63.7 199.2 63.8 198.8 63.8C198.7 63.8 198.4 63.7 197.8 63.5C197.3 63.3 196.7 63.2 196 63.2C195.3 63.2 194.7 63.3 194.4 63.6C194 63.8 193.8 64.2 193.8 64.6C193.8 65 194 65.3 194.3 65.5C194.6 65.8 195 66 195.5 66.1C196 66.3 196.5 66.5 197 66.7C197.5 66.9 198.1 67.2 198.5 67.5C199 67.8 199.4 68.2 199.7 68.8C200 69.3 200.2 70 200.2 70.7C200.2 71.9 199.8 72.9 198.9 73.6C198 74.3 196.9 74.6 195.5 74.6C194.2 74.6 193.2 74.4 192.3 73.9C191.5 73.4 191 72.9 191 72.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M203.5 73.6V61.9C203.5 61.7 203.6 61.5 203.8 61.4C204 61.3 204.3 61.2 204.6 61.2H211.6C211.9 61.2 212.1 61.3 212.2 61.5C212.3 61.7 212.4 61.9 212.4 62.2C212.4 62.5 212.3 62.7 212.2 62.9C212.1 63.1 211.9 63.2 211.6 63.2H206V66.8H209C209.2 66.8 209.4 66.9 209.6 67.1C209.7 67.3 209.8 67.5 209.8 67.8C209.8 68 209.7 68.2 209.6 68.4C209.5 68.6 209.3 68.7 209 68.7H206V72.3H211.6C211.8 72.3 212 72.4 212.2 72.6C212.3 72.8 212.4 73.1 212.4 73.3C212.4 73.6 212.3 73.8 212.2 74C212.1 74.2 211.9 74.3 211.6 74.3H204.6C204.3 74.3 204 74.2 203.8 74.1C203.6 74 203.5 73.9 203.5 73.6Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M227.4 70.3V61.8C227.4 61.6 227.5 61.4 227.8 61.3C228 61.2 228.4 61.1 228.7 61.1C229.1 61.1 229.4 61.2 229.6 61.3C229.8 61.4 230 61.6 230 61.8V70.3C230 71.7 230.7 72.4 232.2 72.4C233.6 72.4 234.4 71.7 234.4 70.3V61.8C234.4 61.6 234.5 61.4 234.8 61.3C235.1 61.2 235.4 61.1 235.7 61.1C236.1 61.1 236.4 61.2 236.6 61.3C236.8 61.4 237 61.6 237 61.8V70.3C237 71.7 236.6 72.8 235.7 73.4C234.8 74.1 233.7 74.4 232.3 74.4C230.9 74.4 229.7 74.1 228.9 73.4C227.8 72.8 227.4 71.8 227.4 70.3Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M9.49999 61.4C9.49999 61.4 9.79999 61 10.2 61.1C10.2 61.1 11 61.3 11.2 61.6C11.2 61.6 11.4 61.9 11.2 62.2L9.99999 65C9.99999 65 9.79999 65.3 9.19999 65.3C9.19999 65.3 8.89999 65.3 8.59999 65C8.59999 65 8.19999 64.6 8.39999 64.2L9.49999 61.4Z"
					fill={darkPrimaryColor}
				/>
				<path
					d="M222.1 71.5L222.8 73.9C222.9 74.2 223.2 74.4 223.8 74.4C224.2 74.4 224.4 74.3 224.8 74.1C225.2 73.9 225.4 73.7 225.4 73.4C225.4 73.4 225.4 73.3 225.4 73.2L221.6 62C221.5 61.7 221.3 61.5 221 61.3C220.6 61.1 220 61.1 219.6 61.1C219.2 61.1 218.6 61.1 218.2 61.3C217.9 61.5 217.7 61.7 217.6 62L213.8 73.2C213.8 73.3 213.8 73.4 213.8 73.4C213.8 73.7 214 73.9 214.4 74.1C214.8 74.3 215 74.4 215.4 74.4C215.9 74.4 216.3 74.2 216.4 73.9L217.1 71.5H222.1ZM217.8 69.6L219.7 63.4L221.6 69.6H217.8Z"
					fill={darkPrimaryColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="236.9" height="74.5" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default { Base, Icon, Dark, Header }
