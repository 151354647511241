export const STARTUP = 'auth/startup'
export const STARTUP_SUCCESS = 'auth/startupSuccess'
export const STARTUP_FAILURE = 'auth/startupFailure'

export const RESET_ERROR = 'RESET_ERROR'

export const POST_SIGNIN = 'POST_SIGNIN'
export const POST_SIGNIN_FAILURE = 'POST_SIGNIN_FAILURE'
export const POST_SIGNIN_SUCCESS = 'POST_SIGNIN_SUCCESS'

export const STORE_EMAIL = 'STORE_EMAIL'
export const STORE_EMAIL_FAILURE = 'STORE_EMAIL_FAILURE'
export const STORE_EMAIL_SUCCESS = 'STORE_EMAIL_SUCCESS'

export const POST_PASSWORD_RETRIEVE = 'POST_PASSWORD_RETRIEVE'
export const POST_PASSWORD_RETRIEVE_FAILURE = 'POST_PASSWORD_RETRIEVE_FAILURE'
export const POST_PASSWORD_RETRIEVE_SUCCESS = 'POST_PASSWORD_RETRIEVE_SUCCESS'

export const PUT_PASSWORD_CREATE = 'PUT_PASSWORD_CREATE'
export const PUT_PASSWORD_CREATE_FAILURE = 'PUT_PASSWORD_CREATE_FAILURE'
export const PUT_PASSWORD_CREATE_SUCCESS = 'PUT_PASSWORD_CREATE_SUCCESS'

export const SIGNOUT = 'SIGNOUT'
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'

export const startup = () => ({
	type: STARTUP,
})

export const startupSuccess = () => ({
	type: STARTUP_SUCCESS,
})

export const startupFailure = (error) => ({
	type: STARTUP_FAILURE,
	payload: {
		error,
		errorString: error && error.toString(),
	},
})

export const resetState = () => ({
	type: RESET_ERROR,
})

export const storeEmail = (email) => ({
	type: STORE_EMAIL,
	payload: {
		email,
	},
})

export const storeEmailSuccess = (email) => ({
	type: STORE_EMAIL_SUCCESS,
	payload: {
		email,
	},
})

export const storeEmailFailure = (error) => ({
	type: STORE_EMAIL_FAILURE,
	payload: {
		error,
	},
})

export const firstSignin = (formData, redirectUrl = '/') => ({
	type: POST_SIGNIN,
	payload: {
		formData,
		redirectUrl,
	},
})

export const firstSigninSuccess = (formData, redirectUrl = '/') => ({
	type: POST_SIGNIN,
	payload: {
		formData,
		redirectUrl,
	},
})

export const firstSigninFailure = (formData, redirectUrl = '/') => ({
	type: POST_SIGNIN,
	payload: {
		formData,
		redirectUrl,
	},
})

export const postSignin = (formData, redirectUrl = '/') => ({
	type: POST_SIGNIN,
	payload: {
		formData,
		redirectUrl,
	},
})

export const postSigninSuccess = (token) => ({
	type: POST_SIGNIN_SUCCESS,
	payload: {
		token,
	},
})

export const postSigninFailure = (err) => ({
	type: POST_SIGNIN_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString().split('Error:')[1],
	},
})

export const postPasswordRetrieve = (email) => ({
	type: POST_PASSWORD_RETRIEVE,
	payload: {
		email,
	},
})

export const postPasswordRetrieveSuccess = () => ({
	type: POST_PASSWORD_RETRIEVE_SUCCESS,
})

export const postPasswordRetrieveFailure = (err) => ({
	type: POST_PASSWORD_RETRIEVE_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
	},
})

export const putPasswordCreate = (formData, url) => ({
	type: PUT_PASSWORD_CREATE,
	payload: {
		formData,
		url,
	},
})

export const putPasswordCreateSuccess = () => ({
	type: PUT_PASSWORD_CREATE_SUCCESS,
})

export const putPasswordCreateFailure = (err) => ({
	type: PUT_PASSWORD_CREATE_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
	},
})

export const signout = () => {
	return {
		type: SIGNOUT,
	}
}

export const signoutSuccess = () => ({
	type: SIGNOUT_SUCCESS,
})

export const signoutFailure = (err) => ({
	type: SIGNOUT_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
	},
})
