import defaultTheme from '__theme__/theme.default'

const primary = '#005EB8'
const secondary = '#93C90E'
const darkPrimary = '#005EB8'
const illustration = '#f63c55'
const hover = '#93C90E'
const greyBorder = '#d9d9d9'
const darkGrey = '#ccc'
const lightGrey = '#f6f6f6'

const errorColor = '#f5222d'
const validColor = '#52c41a'

export default {
	...defaultTheme,
	colors: {
		...defaultTheme.colors,
		primary,
		secondary,
		darkPrimary,
		greyBorder,
		darkGrey,
		lightGrey,
		hover,
		illustration,
	},
	status: {
		apiFailure: errorColor,
		failure: errorColor,
		inQueue: '#ffb818',
		invalid: errorColor,
		stopped: errorColor,
		success: validColor,
		published: validColor,
		indexed: '#4F4F4F',
		uploading: '#1890ff',
		pending: '#1890ff',
		waiting: '#4F4F4F',
	},
}
