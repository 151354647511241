import Cookies from 'universal-cookie'

const cookies = new Cookies()

const options = {
	path: '/',
	sameSite: 'Strict',
}

export const setToCookiesService = (value, name) => cookies.set(name, value, options)

export const removeFromCookiesService = (name) => cookies.remove(name, options)

export const getFromCookiesService = (name) => {
	const cookie = cookies.get(name)
	return cookie === 'undefined' || cookie === 'null' ? null : cookie
}
