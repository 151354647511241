import { STARTUP, STARTUP_SUCCESS } from 'modules/root/actions/rootActions'

const initialState = {
	isReady: false,
	error: null,
	message: null,
}

const rootReducers = (state = initialState, { type, payload }) => {
	if (type.endsWith('FAILURE')) {
		return { ...state, error: payload.errorString }
	}

	switch (type) {
		case STARTUP:
			return { ...state, isReady: false }

		case STARTUP_SUCCESS:
			return { ...state, isReady: true }

		default:
			return state
	}
}

export default rootReducers
