import { Layout } from 'antd'
import styled from 'styled-components'

const { Content: AntdContent } = Layout

export const Root = styled(Layout)`
	display: flex;
	flex-direction: row;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const Content = styled(AntdContent)`
	background-color: ${({ theme }) => theme.colors.lightGrey};
	height: 100%;
`
