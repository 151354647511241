const title = 'rgba(0, 0, 0, 0.85)'
const body = 'rgba(0, 0, 0, 0.65)'
const caption = '#595959'

const background = '#e9e9e9'
const mediaCardBackground = '#D8EFFF'

const white = '#FFF'
const black = '#000'

const defaultTheme = {
	breakpoints: [480, 576, 768, 992, 1200, 1600],
	devices: {
		xs: '@media (max-width: 480px)',
		sm: '@media (max-width: 576px)',
		md: '@media (max-width: 768px)',
		lg: '@media (max-width: 992px)',
		xl: '@media (max-width: 1200px)',
		xxl: '@media (max-width: 1600px)',
	},
	container: {
		web: '1186px',
		bo: '100%',
		subContainer: '600px',
	},
	layout: {
		headerHeight: 70,
	},
	colors: {
		footerBackground: '#2b2e30',
		background,
		mediaCardBackground,
		white,
		black,
		font: {
			title,
			body,
		},
	},
	spaces: {
		xSmall: '0.5em',
		small: '1em',
		medium: '2em',
		large: '3em',
		xLarge: '6em',
	},
	font: {
		h1: `
			font-family: Roboto !important;
			font-size: 24px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: 28px !important;
			letter-spacing: 0em !important;
			color: ${title};
		`,
		h2: `
			font-family: Roboto !important;
			font-size: 20px !important;
			font-style: normal !important;
			font-weight: 500 !important;
			line-height: 27px !important;
			letter-spacing: 0em !important;
			color: ${title};
		`,
		h3: `
			font-family: Roboto !important;
			font-size: 18px !important;
			font-style: normal !important;
			font-weight: 500 !important;
			line-height: 21px !important;
			letter-spacing: 0em !important;
			color: ${title};
		`,
		h4: `
			font-family: Roboto !important;
			font-size: 16px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: 19px !important;
			letter-spacing: 0em !important;
			color: ${title};
		`,
		h5: `
			font-family: Roboto !important;
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: 16px !important;
			letter-spacing: 0em !important;
			color: ${title};
		`,
		caption: `
			font-size: 12px !important;
			color: ${caption} !important;
			margin-bottom: 1.5em !important;
		`,
	},
	shadows: {
		xSmall: '1px 1px 3px #ccc;',
		small: '1px 1px 10px #ccc;',
		medium: '2px 5px 5px #ccc;',
	},
	borderRadius: {
		small: '3px',
		medium: '5px',
		large: '8px',
		circle: '100px',
	},
	authLogo: `
		margin-bottom: 5em;

		@media (max-width: 480px) {
			margin-bottom: 2em;
		}
	`,
	loader: `
		align-items: center;
		justify-content: center;
		display: flex;
		background: none;
		width: 100%;
		height: 100%;
	`,
	authForm: `
		min-height: 100vh;
		background-color: #F0F2F5;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		& > div:last-of-type {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			background-color: #FFF;;
			padding: 2em;
			width: 350px;

			& > h1 {
				margin-bottom: 1em;
			}
			
			@media (max-width: 480px) {
				width: 100%;
			}
			
			& > form {
				width: 100%;

				& > div:last-child {
					margin: 0
				}
			}
		}
		
	`,
}

export default defaultTheme
