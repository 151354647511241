export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'

export const STORE_CURRENT_USER = 'STORE_CURRENT_USER'
export const STORE_CURRENT_USER_FAILURE = 'STORE_CURRENT_USER_FAILURE'
export const STORE_CURRENT_USER_SUCCESS = 'STORE_CURRENT_USER_SUCCESS'

export const DESTROY_USER = 'DESTROY_USER'
export const DESTROY_USER_FAILURE = 'DESTROY_USER_FAILURE'
export const DESTROY_USER_SUCCESS = 'DESTROY_USER_SUCCESS'

export const storeCurrentUser = (user) => ({
	type: STORE_CURRENT_USER,
	payload: {
		user,
	},
})

export const storeCurrentUserSuccess = (user) => ({
	type: STORE_CURRENT_USER_SUCCESS,
	payload: {
		user,
	},
})

export const storeCurrentUserFailure = (err) => ({
	type: STORE_CURRENT_USER_FAILURE,
	payload: {
		error: err,
		errorString: err && err.toString(),
	},
})

export const getCurrentUser = () => ({
	type: GET_CURRENT_USER,
})

export const getCurrentUserSuccess = (user) => ({
	type: GET_CURRENT_USER_SUCCESS,
	payload: {
		user,
	},
})

export const getCurrentUserFailure = (err) => ({
	type: GET_CURRENT_USER_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})

export const destroyUser = () => ({
	type: DESTROY_USER,
})

export const destroyUserSuccess = () => ({
	type: DESTROY_USER_SUCCESS,
})

export const destroyUserFailure = (err) => ({
	type: DESTROY_USER_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
