import { Spin } from 'antd'
import styled from 'styled-components'

export const Root = styled.div`
	background: none;
	height: 100%;
`

export const Loader = styled(Spin)`
	${({ theme }) => theme.loader};
	height: 100%;
`
