import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { createStore } from 'redux-dynamic-modules'
import { getSagaExtension } from 'redux-dynamic-modules-saga'
import React from 'react'
import frFR from 'antd/lib/locale/fr_FR'
import 'antd/dist/antd.compact.css'

import App from 'components/app/App'

import sagas from 'sagas'

import rootModule from 'modules/root'

import theme from '__theme__/theme'

const sagaExtension = getSagaExtension()

const store = createStore({
	initialState: { root: null },
	extensions: [sagaExtension],
})

sagaExtension.middleware[0].run(sagas)

store.addModule(rootModule)

const Root = () => {
	return (
		<Provider store={store}>
			<ConfigProvider locale={frFR}>
				<ThemeProvider theme={theme.enedis}>
					<App />
				</ThemeProvider>
			</ConfigProvider>
		</Provider>
	)
}

export default Root
