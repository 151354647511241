import { DeploymentUnitOutlined, FileSearchOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons'
import { Menu, Tooltip } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { scrollToTop } from 'utils/animationUtils'
import { navigateTo } from 'actions/navActions'

const NavMenu = ({ isOpen }) => {
	const dispatch = useDispatch()

	const currentUrl = window.location.pathname.split('/')[1]
	const [activePage, setActivePage] = useState(!isEmpty(currentUrl) ? currentUrl : 'users')

	const onNavigationClick = (page) => {
		setActivePage(page)
		scrollToTop()
		dispatch(navigateTo(page, null))
	}

	const listOfPages = [
		{
			page: '/Messages',
			icon: FileSearchOutlined,
			hasChildren: false,
			label: 'Messages',
		},
		{ page: '/Users', icon: UserOutlined, hasChildren: false, subPages: null, label: 'Users' },
		{ page: '/Activation', icon: DeploymentUnitOutlined, hasChildren: false, subPages: null, label: 'Activation' },
	]

	const currentOpenSubMenu = listOfPages.find(
		({ subPages }) => !isNil(subPages) && subPages.find(({ subPage }) => subPage === activePage)
	)

	const resolveMenuItem = (label, page, icon, parentPage) => {
		const CustomIcon = icon
		return (
			<Menu.Item
				data-testid="__nav-menu-button__"
				key={page}
				onClick={() => onNavigationClick(page)}
				tabIndex="0"
				onKeyPress={({ key }) => key === 'Enter' && onNavigationClick(page)}
				icon={
					<Tooltip title={label} placement="right">
						<CustomIcon />
					</Tooltip>
				}
			>
				<Tooltip title={label} placement="right">
					{isOpen ? label : null}
				</Tooltip>
			</Menu.Item>
		)
	}

	return (
		<Menu defaultSelectedKeys={[activePage ?? 'users']} defaultOpenKeys={[currentOpenSubMenu?.page]} mode="inline">
			{map(listOfPages, ({ label, page, icon, hasChildren, subPages }, index) => {
				const CustomIcon = icon
				return resolveMenuItem(label, page, icon)
			})}
		</Menu>
	)
}

export default NavMenu
