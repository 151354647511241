import { call, fork, put, take, takeEvery } from 'redux-saga/effects'

import { FETCH_TOKEN_SUCCESS, fetchToken } from 'actions/tokenActions'
import { REQUEST, requestFailure, requestSuccess } from 'actions/apiActions'
import { navigateTo } from 'actions/navActions'
import { destroyToken } from 'actions/tokenActions'
import { requestService } from 'services/apiServices'

export function* watchRequest() {
	yield takeEvery(REQUEST, requestSaga)
}

export function* requestSaga({ payload }) {
	const { resourceId } = payload
	try {
		yield put(fetchToken())
		const {
			payload: { token },
		} = yield take(FETCH_TOKEN_SUCCESS)
		let { requestUrl, requestOptions } = payload

		requestOptions = token
			? { ...requestOptions, headers: { ...requestOptions?.headers, 'X-API-Token': token } }
			: requestOptions

		const { body, status, headers } = yield call(requestService, requestUrl, requestOptions)
		yield put(requestSuccess(body, status, headers, resourceId))
	} catch (err) {
		if (err.status === 'NotAuthorizedException') {
			yield put(destroyToken())
			yield put(navigateTo('/auth'))
		}
		yield put(requestFailure(err, resourceId))
	}
}

export default function* apiSaga() {
	yield fork(watchRequest)
}
