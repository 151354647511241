import React from 'react'

import * as Styled from './__styles__/Footer.styles'

const Footer = () => {
	return <Styled.Root />
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
