import { fork } from 'redux-saga/effects'

import apiSaga from './apiSagas'
import cookiesSagas from './cookiesSagas'
import labelSaga from './labelSagas'
import navSagas from './navSagas'
import storageSaga from './storageSagas'
import tokenSaga from './tokenSagas'
import userInfoSaga from './userInfoSagas'
import userSaga from './userSagas'

export default function* () {
	yield fork(apiSaga)
	yield fork(cookiesSagas)
	yield fork(storageSaga)
	yield fork(tokenSaga)
	yield fork(navSagas)
	yield fork(userInfoSaga)
	yield fork(userSaga)
	yield fork(labelSaga)
}
