import { fork, put, take, takeLatest } from 'redux-saga/effects'
import { isNil } from 'lodash'
import decode from 'jwt-decode'

import {
	DESTROY_TOKEN,
	FETCH_TOKEN,
	STORE_TOKEN,
	destroyTokenFailure,
	destroyTokenSuccess,
	fetchTokenFailure,
	fetchTokenSuccess,
	storeTokenFailure,
	storeTokenSuccess,
} from '../actions/tokenActions'

import {
	GET_FROM_COOKIES_SUCCESS,
	REMOVE_FROM_COOKIES_SUCCESS,
	SET_TO_COOKIES_SUCCESS,
	getFromCookies,
	removeFromCookies,
	setToCookies,
} from '../actions/cookieActions'

const TOKEN_NAME = 'token'

export function* watchFetchToken() {
	yield takeLatest(FETCH_TOKEN, fetchTokenSaga)
}

export function* fetchTokenSaga() {
	try {
		yield put(getFromCookies(TOKEN_NAME, 'token'))

		const {
			payload: { value },
		} = yield take(({ type, payload: { resourceId } }) => type === GET_FROM_COOKIES_SUCCESS && resourceId === 'token')

		let token = value
		if (!isNil(token)) {
			const { exp } = decode(token)
			if (exp * 1000 < Date.now()) {
				token = null
			}
			yield put(fetchTokenSuccess(token))
		} else {
			yield put(fetchTokenSuccess(null))
		}
	} catch (err) {
		yield put(fetchTokenFailure(err))
	}
}

export function* watchStoreToken() {
	yield takeLatest(STORE_TOKEN, storeTokenSaga)
}

export function* storeTokenSaga({ payload }) {
	try {
		yield put(setToCookies(payload.token, TOKEN_NAME))
		yield take(SET_TO_COOKIES_SUCCESS)

		yield put(storeTokenSuccess(payload.token))
	} catch (err) {
		yield put(storeTokenFailure(err))
	}
}

export function* watchDestroyToken() {
	yield takeLatest(DESTROY_TOKEN, destroyTokenSaga)
}

export function* destroyTokenSaga() {
	try {
		yield put(removeFromCookies(TOKEN_NAME))
		yield take(REMOVE_FROM_COOKIES_SUCCESS)

		yield put(destroyTokenSuccess())
	} catch (err) {
		yield put(destroyTokenFailure(err))
	}
}

export default function* tokenSagas() {
	yield fork(watchFetchToken)
	yield fork(watchStoreToken)
	yield fork(watchDestroyToken)
}
